import React, {Component} from 'react';
import {Button, Container, Form, Grid, Message, Modal} from 'semantic-ui-react'
import ErrorEmptyFormInput from "../errorEmtpyFormInput";
import axios from "axios";
import {resolveErrorMessage} from '../../utils/utils'
import {
    errorDuplicateFein,
    issueWithFEIN,
    unableToDesignateNewSigner,
    userAccountAlreadyHasAContract
} from "../../utils/strings";
import {ClickableText, CustomModalHeader, CustomEmailInput} from '../custom-common';
const SUBMIT_ERROR_STATUS = "ERROR";
const SUBMIT_STATUS_NOT_FOUND = "NOT_FOUND";
const SUBMIT_SUCCESS_STATUS = "SUCCESS";

class DesignateSigner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            requesting: false,
            submitError: null,
            longPolling: null,
        };
        this.required = ['firstName', 'lastName', 'email'];
    }

    componentWillUnmount() {
        const {longPolling} = this.state
        if (longPolling) {
            clearInterval(longPolling)
        }
    }

    closeConfigShow = (closeOnEscape, closeOnDimmerClick) => () => {
        this.setState({closeOnEscape, closeOnDimmerClick})
        this.props.onOpen(true)
    };

    close = () => this.props.onOpen(false);

    handleChange = (e, {name, value}) => this.setState({[name]: value})

    resolveSubmitGroupInfoError = (e) => {
        const error = resolveErrorMessage(e, unableToDesignateNewSigner)
        switch (error) {
            case issueWithFEIN:
                return errorDuplicateFein
            case userAccountAlreadyHasAContract:
                return userAccountAlreadyHasAContract
            default:
                return unableToDesignateNewSigner
        }
    }

    pollSubmitStatus = (entryId) => {
        const handleSuccess = () => {
            this.setState({requesting: false, submitError: '', longPolling: null})
            this.props.history.push("/accountCreation/contractSent")
        }
        const handleError = (error) => {
            const submitError = resolveErrorMessage(error, "An unexpected error has occurred.")
            this.setState({requesting: false, submitError, longPolling: null})
        }
        const checkSubmitGroupInfoStatus = async () => {
            try {
                const {data: {error, submitStatus}} = await axios.post("/api/employer/v2/checkSubmitGroupInfoStatus", {entryId})
                const isSubmitSuccess = submitStatus === SUBMIT_SUCCESS_STATUS
                const isSubmitError = [SUBMIT_ERROR_STATUS, SUBMIT_STATUS_NOT_FOUND].includes(submitStatus)
                if (isSubmitSuccess) {
                    handleSuccess()
                } else if (isSubmitError) {
                    handleError(error)
                } else {
                    const timeout = setTimeout(checkSubmitGroupInfoStatus, 2000)
                    this.setState({longPolling: timeout})
                }
            } catch (error) {
                handleError(error)
            }
        }
        checkSubmitGroupInfoStatus()
    }

    send = async (e) => {
        e.preventDefault();
        const {firstName, lastName, email} = this.state;
        const {
            taxId,
            legalEntityName,
            address,
            zipcode,
            city,
            affiliatesSubsidiaries,
            stateValue,
            ratedClasses,
            newEligibilityInfo,
            variesByClass
        } = this.props.group;

        let eligibilities = [];
        if (!!variesByClass) {
            ratedClasses.forEach(groupClass => {
                const elegibility = {
                    onlyUnmarried: groupClass.onlyUnmarriedChildren,
                    benefitTerminationRules: groupClass.benefitTerminationRules,
                    activelyAtWorkHours: parseFloat(groupClass.activelyAtWorkHours),
                    civilUnionEligible: groupClass.civilUnionEligible,
                    domesticPartnerEligible: groupClass.domesticPartnerEligible,
                    subjectToERISA: groupClass.subjectToERISA,
                    groupClassId: groupClass.groupClass.id
                }
                if (groupClass.hasOwnProperty('continuationProvisions')) {
                    if (groupClass.continuationProvisions) {
                        elegibility.coverageContinued = true
                        elegibility.hasMilitaryLeaveOfAbsence = !!groupClass.hasMilitaryLeaveOfAbsence;
                        elegibility.hasSabbaticalLeaveOfAbsence = !!groupClass.hasSabbaticalLeaveOfAbsence;
                        elegibility.hasLeaveOfAbsenceDueToQuarantine = !!groupClass.hasLeaveOfAbsenceDueToQuarantine;
                        elegibility.hasFurlough = !!groupClass.hasFurlough;
                        elegibility.hasLaborStrike = !!groupClass.hasLaborStrike;
                    } else {
                        elegibility.coverageNotContinued = false
                    }
                }
                eligibilities.push(elegibility)
            })
        } else {
            ratedClasses.forEach(groupClass => {
                const elegibility = {
                    onlyUnmarried: newEligibilityInfo.onlyUnmarriedChildren,
                    benefitTerminationRules: newEligibilityInfo.benefitTerminationRules,
                    activelyAtWorkHours: parseFloat(newEligibilityInfo.activelyAtWorkHours),
                    civilUnionEligible: newEligibilityInfo.civilUnionEligible,
                    domesticPartnerEligible: newEligibilityInfo.domesticPartnerEligible,
                    subjectToERISA: newEligibilityInfo.subjectToERISA,
                    groupClassId: groupClass.groupClass.id
                }
                eligibilities.push(elegibility)
            })
        }

        let payload = {
            fein: taxId,
            legalEntityName: legalEntityName,
            streetAddress: address,
            zipCode: zipcode,
            city,
            stateValue,
            affiliatesSubsidiaries,
            acceptTerms: true,
            designatedSigner: {
                firstName,
                lastName,
                emailAddress: email
            },
            ackSigningAuthority: false,
            eligibilities: eligibilities,
            continuationProvisions: newEligibilityInfo.continuationProvisions
        }
        this.setState({requesting: true})
        try {
            const {data: {entryId}} = await axios.post("/api/employer/v2/submitGroupInfo", payload, {headers: {'Content-Type': 'application/json'}});
            this.pollSubmitStatus(entryId)
        } catch (e) {
            const submitError = this.resolveSubmitGroupInfoError(e)
            this.setState({
                submitError,
                requesting: false
            });
        }
    }

    validate = () => {
        let isError = false;
        for (let i = 0; i < this.required.length; i++) {
            let item = this.required[i];
            if (!this.state.hasOwnProperty(item) || this.state[item] === null || this.state[item].length === 0) {
                isError = true;
                break;
            }
        }
        return isError;
    }

    invalidEmail = (value) => {
        const partsAt = value.split("@");
        const suffix = (partsAt.length === 2 && partsAt[1].split(".")) || [];
        const charRestriction = /^[a-zA-Z0-9@]*(.[a-zA-Z0-9]+)*(-[a-zA-Z0-9])*(\+[a-zA-Z0-9])*$/i;
        return partsAt.length !== 2 || suffix.length === 1 || suffix[suffix.length - 1].length < 2 || !charRestriction.test(value)
    }

    render() {
        const {closeOnEscape, closeOnDimmerClick, firstName, lastName, email, requesting, submitError} = this.state;
        const {open} = this.props;
        const hasErrors = this.validate();

        return (
            <React.Fragment>
                <Container style={{fontSize: '14px', paddingTop: '8px'}}>
                    Not the person who needs to sign? <ClickableText onClick={this.closeConfigShow(true, true)}>Designate new Signer</ClickableText>
                </Container>

                <Modal open={open}
                       closeOnEscape={closeOnEscape}
                       closeOnDimmerClick={closeOnDimmerClick}
                       onClose={this.close}
                       size='tiny'
                >
                    <CustomModalHeader title={"Designate a new signer"} onClose={this.close}/>
                    <Modal.Content>
                        <Grid container columns={1}>
                            <Grid.Column>
                                <Form>
                                    <Form.Group widths={"equal"}>
                                        <ErrorEmptyFormInput
                                            fluid
                                            required
                                            label='First name'
                                            name='firstName'
                                            value={firstName}
                                            onChange={this.handleChange}
                                        />
                                        <ErrorEmptyFormInput
                                            fluid
                                            required
                                            label='Last name'
                                            name='lastName'
                                            value={lastName}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                    <CustomEmailInput
                                            fluid
                                            label='Email'
                                            required
                                            name={"email"}
                                            value={email}
                                            onChange={(event, {value, name}) => {
                                                this.handleChange(event, {value: value, name});
                                            }}
                                            placeholder=''
                                    />
                                </Form>
                            </Grid.Column>
                            <Grid.Column textAlign={"right"}>
                                <Button onClick={this.close} basic secondary disabled={requesting}>Cancel</Button>
                                <Button primary
                                        onClick={this.send}
                                        disabled={hasErrors || this.invalidEmail(this.state.email) || requesting}
                                        loading={requesting}
                                >
                                    Send contract
                                </Button>
                            </Grid.Column>
                        </Grid>
                        {submitError && <Message negative size="mini">{submitError}</Message>}
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        )
    }
}

export default DesignateSigner;
