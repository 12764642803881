import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect, useDispatch } from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import {Button, Form, Input, Message} from 'semantic-ui-react'
import { resolveErrorMessage } from '../../../../utils/utils'
import STRATEGIC_PARTNER from "../../../../constants/strategicPartners"
import { resolveStepUrl } from '../../../../utils/common'
import { setLogin, logout, setAuthMeta, setMfaType, setRememberDays } from '../../../../actions/authActions'
import { setLeftNavigation } from "../../../../actions/navigationActions"
import {CustomContainer, CustomDivider, CustomEmailInput} from '../../../custom-common';

const mapStateToProps = (state, ownProps) => {
  return {
      ...ownProps
  }
};

const LokedOutMessage = () => {
  return (
    <>
      <p>
        Your account has been locked for security reasons. For help, contact us
        at{" "}
        <a
          href={`mailto:${STRATEGIC_PARTNER.CONTACT_SUPPORT.EMAIL}`}
          className={"textLink"}
          style={{color: '#212121'}}
        >
          {STRATEGIC_PARTNER.CONTACT_SUPPORT.EMAIL}
        </a>{" "}
        or call{" "}
        <a
          href={`tel:${STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}`}
          className="textLink"
          style={{color: '#212121'}}
        >
          {STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}
        </a>
        .
      </p>
    </>
  );
};

export const LoginForm = ({ className }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [form, setForm] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  useEffect(() => {
    dispatch(logout())
    if(history?.location?.isLocked){
      setError(<LokedOutMessage />);
    }
  }, [dispatch])


  const handleChange = e => {
    const { name, value } = e.target

    setForm(form => ({ ...form, [name]: value }))
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (loading) return

    setError(null)

    const { email, password } = form

    if (!email) return setError('Please enter your email address')
    if (!password) return setError('Please enter your password')

    setLoading(true)

    try {
      const { data } = await axios.post('/api/employer/v2/login', {
        email: email.trim(),
        password: password
      })

      if(data.isLocked){
        setError(<LokedOutMessage />);
      } else {
        postLogin(email.trim(), data)
      }
    } catch (e) {
      setError(resolveErrorMessage(e, 'An unexpected error has occurred.'))
    }
    setLoading(false)
  }

  const postLogin = (email, loginResponse) => {
    const {
      employerName : username,
      redirectAuthenticated,
      step,
      ...auth
    } = loginResponse

    if (redirectAuthenticated) return window.location = redirectAuthenticated;

    const loginData = {
        ...auth,
        username
    }

    dispatch(setLogin(loginData));
    dispatch(setAuthMeta(email, auth.mfaEnabled, auth.mfaNotEnabledGracePeriodEnded));
    dispatch(setMfaType(auth.type ? auth.type : 'TOTP'))
    dispatch(setRememberDays(loginResponse.rememberDeviceDays ? loginResponse.rememberDeviceDays : undefined))

    if(step !== 'portal'){
      history.push({ pathname: resolveStepUrl(step) })
    } else if (step === 'portal' && auth.mfaEnabled && auth.authToken ) {
      history.push({ pathname: resolveStepUrl(step) });
    } else {
      dispatch(setLeftNavigation(false));
      history.push({ pathname: '/mfa' });
    }
  }


  return (
      <CustomContainer basic className={'noPadding'} loading={loading}>
          {error && <Message error>{error}</Message>}
          <Form onSubmit={handleSubmit}>
              <CustomEmailInput
                type='email'
                name='email'
                value={form.email || ''}
                onChange={handleChange}
                disabled={loading}
              />
              <Form.Field>
                  <label>Password</label>
                  <Input
                    type='password'
                    name='password'
                    value={form.password || ''}
                    onChange={handleChange}
                    disabled={loading}
                  />
              </Form.Field>
              <CustomDivider small hidden/>
              <Button primary fluid disabled={loading}>Login</Button>
          </Form>
          <CustomDivider small hidden/>
          <Link className={'small'} to='/forgotPassword'>Forgot password?</Link>
          <CustomDivider section hidden/>
          <p className={'smaller'}>
              Need to access your Partner Portal? <a target='_blank' href='/employer/login'>Click here</a>
          </p>
      </CustomContainer>
  )
}

export default connect(mapStateToProps)(LoginForm)
