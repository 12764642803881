import React, {Component} from 'react';
import {
    Button,
    Container,
    Form,
    Grid,
    Input,
    Loader,
    Message,
    Header
} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import axios from "axios";
import Footer from "./footer";
import {resolveErrorMessage} from "../utils/utils";
import {StrategicPartnerLabel} from "./common";
import PrePortalHeader from "./prePortalHeader";
import IMAGE from "../constants/images";
import {CustomDivider, S3Image} from './custom-common';

class CreatePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasAgreeToTermsAndPrivacy: false,
            password: '',
            confirmPassword: '',
            loading: false,
            requesting: false,
            submitError: null,
            tokenError: null,
            carrierDisclaimer: null,
            validationErrors: {
                password: null,
                confirmPassword: null,
            }
        };
    }

    async componentDidMount() {
        const token = new URLSearchParams(window.location.search).get("token");
        if (!token) {
            this.setState({tokenError: "Token is invalid"})
            return
        }
        await this.postPrepareCreatePassword(token);
    }

    async postPrepareCreatePassword(token) {
        try {
            const payload = {token};
            this.setState({loading: true})
            const response = await axios.post("/api/employer/v2/prepareSetPassword", payload, {headers: {'Content-Type': 'application/json'}});
            if (response.data.hasPassword && !response.data.mustResetPassword) {
                this.setState({loading: false});
                this.props.history.push("/login");
            } else if (response.data.valid) {
                let carrierDisclaimer = response.data?.carrierDisclaimer
                this.setState({carrierDisclaimer, loading: false})
            } else {
                this.setState({loading: false, tokenError: "Token is invalid"})
            }
        } catch (e) {
            const errorMessage = e.code === 'ECONNABORTED' || !e.response ? "Error: Please try again later"  :  resolveErrorMessage(e, "Token is invalid");
            this.setState({ loading: false, tokenError: errorMessage});
        }
    }

    handleChange = (e, {name, value}) => {
        const {validationErrors} = this.state
        validationErrors[name] = null
        this.setState({[name]: value, validationErrors: validationErrors})
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const errors = this.validate();
        if (errors) {
            return;
        }

        try {
            const token = new URLSearchParams(window.location.search).get("token");
            let payload = {token: token, password: this.state.password}
            this.setState({requesting: true, submitError: null});
            await axios.post("/api/employer/v2/createPassword", payload, {headers: {'Content-Type': 'application/json'}});
            this.setState({requesting: false});
            this.props.history.push({pathname: this.props.nextRoute});
        } catch (e) {
            this.setState({
                requesting: false,
                submitError: resolveErrorMessage(e, "Unable to create password")
            });
        }
    }

    toggleTermsAndPrivacy = () => {
        const {hasAgreeToTermsAndPrivacy} = this.state;
        this.setState({hasAgreeToTermsAndPrivacy: !hasAgreeToTermsAndPrivacy});
    }

    validate = () => {
        const {validationErrors, password, confirmPassword} = this.state
        let error = !this.validatePassword()
        if (password !== confirmPassword) {
            validationErrors.confirmPassword = "Your passwords do not match. Please try again."
            error = false
        }
        this.setState({validationErrors: validationErrors})
        return error
    }

    validatePasswordMatch = () => {
        const {validationErrors, password, confirmPassword} = this.state
        if (password !== confirmPassword) {
            validationErrors.confirmPassword = "Your passwords do not match. Please try again."
            return false
        }
        return true
    }

    validatePassword = () => {
        const {password, validationErrors} = this.state;
        const invalidPassword = (password.length < 10 || password.search(/[A-Z]/) < 0 || password.search(/[0-9]/) < 0);
        if (invalidPassword) {
            validationErrors.password = "Passwords must contain at least 10 characters, one uppercase letter, and one number."
            this.setState({validationErrors: validationErrors})
            return false
        }
        return true;
    }

    render() {
        const {
            password,
            confirmPassword,
            validationErrors,
            tokenError,
            loading,
            requesting,
            submitError,
            hasAgreeToTermsAndPrivacy,
        } = this.state;
        if (loading) {
            return <Container><Loader active={true}/></Container>
        }
        const disableSubmit = !hasAgreeToTermsAndPrivacy ||
            password.length < 10 ||
            validationErrors.password ||
            confirmPassword !== password ||
            requesting;

        return (
            <div className="employer-main-container">
                <PrePortalHeader/>
                <Container>
                    <CustomDivider hidden/>
                    {tokenError ? (
                            <div style={{marginTop: '150px'}}>
                              <Message negative>{tokenError}</Message>
                            </div>
                    ) : (
                        <div className='employer-createPassword-container'>
                            <S3Image {...IMAGE.WELCOME} />
                            <Header as='h2' textAlign='center' style={{marginTop: 11, marginBottom: 26}}>
                                Welcome to <StrategicPartnerLabel/>
                            </Header>
                            <div style={{display: 'flex', alignItems: "center", flexDirection: "column", textAlign: 'center'}}>
                                <p className={"neutral700Text"} style={{maxWidth: 460, fontSize: 16, marginBottom: 24}}>
                                    Get started creating your account below—<br/>
                                    Passwords must contain at least 10 characters,<br/>
                                    one uppercase letter, and one number.
                                </p>
                            </div>
                            <Grid container stackable centered>
                                <Grid.Column width={4} textAlign='left' className={"pageContent"}>
                                    <Form>
                                        <Form.Field error={!!validationErrors.password} required>
                                            <label>Enter password</label>
                                            <Input
                                                fluid type="password"
                                                name='password'
                                                value={password}
                                                onChange={this.handleChange}
                                                onFocus={() => {
                                                    validationErrors.password = null
                                                    this.setState({validateErrors: validationErrors});
                                                }}
                                                onBlur={(e) => {
                                                    const val = e.target.value;
                                                    this.validatePassword(val);
                                                }}
                                            />
                                            {validationErrors.password && <span
                                                className={"warningRedText smaller"}>{validationErrors.password}</span>}
                                        </Form.Field>
                                        <Form.Field error={!!validationErrors.confirmPassword} required>
                                            <label>Re-enter password</label>
                                            <Input fluid type="password" name='confirmPassword'
                                                   value={confirmPassword}
                                                   onFocus={() => {
                                                       validationErrors.confirmPassword = null
                                                       this.setState({validateErrors: validationErrors});
                                                   }}
                                                   onBlur={() => {
                                                       this.validatePasswordMatch();
                                                   }} onChange={this.handleChange}/>
                                            {!!validationErrors.confirmPassword && <span
                                                className={"warningRedText smaller"}>{validationErrors.confirmPassword}</span>}
                                        </Form.Field>
                                        <Form.Checkbox name="agreeToTerms" checked={hasAgreeToTermsAndPrivacy}
                                                       onChange={this.toggleTermsAndPrivacy}
                                                       label={<label>I accept the <a href="https://www.joinansel.com/terms" target="_blank" rel="noopener noreferrer">Terms</a> and <a href="https://www.joinansel.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy</a></label>}
                                        />
                                        {!!submitError &&
                                        <Form.Field><Message negative>{submitError}</Message></Form.Field>}
                                        <Form.Field>
                                            <Button onClick={this.handleSubmit} primary style={{width: '100%'}}
                                                    disabled={disableSubmit} loading={requesting}>
                                                Sign Up
                                            </Button>
                                        </Form.Field>
                                    </Form>
                                </Grid.Column>
                            </Grid>
                        </div>
                    )}
                </Container>
                <Footer/>
            </div>
        )
    }
}

export default withRouter(CreatePassword);
