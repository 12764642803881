import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Button, Grid, Icon, Header} from 'semantic-ui-react'
import {parseDate} from '../../../utils/date'
import EducationExperienceLinks from './educationExperienceLinks'
import ConfirmEligibleEmplyees from './confirmEligibleEmplyees'
import Analytics from './analytics'
import STRATEGIC_PARTNER from "../../../constants/strategicPartners";
import {S3Image, CustomContainer} from "../../custom-common";
import IMAGE from "../../../constants/images";

const mapStateToProps = ({openEnrollment, portal}, ownProps) => {
    const oe = openEnrollment || {}

    return {
        ...ownProps,
        status: oe.status,
        preEnrollmentEducationLaunchDate: parseDate(oe.preEnrollmentEducationLaunchDate),
        enrollmentStartDate: parseDate(oe.enrollmentStartDate),
        enrollmentEndDate: parseDate(oe.enrollmentEndDate),
        openEnrollmentId: oe.openEnrollmentId,
        renewedContractId: oe.renewedContract.contractId,
        activeContractId: portal.contract.contract.id,
        isBenAdmin: portal.isBenAdmin,
    }
}

class EditBanner extends Component {
    handleEditClick = () => {
        const {onClick} = this.props

        onClick && onClick()
    }

    renderButton = () => {
        const {status} = this.props

        if (status === 'closed') return

        return (
            <Button basic primary compact
                    style={{fontSize: '14px', whiteSpace: 'nowrap'}}
                    onClick={this.handleEditClick}
            >Edit enrollment settings <Icon name="pencil" style={{marginLeft: '1em'}}/></Button>
        )
    }

    renderLink = () => {
        const {isBenAdmin} = this.props;

        if (!isBenAdmin) return null;

        return <EducationExperienceLinks/>
    }

    renderEducationLinkImage = () => {
        const {isBenAdmin} = this.props;

        if (!isBenAdmin) return null;

        return <S3Image alt='Education link image' {...IMAGE.PEACE_OF_MIND_PAYMENT_73PX}/>
    }

    renderStatus = () => {
        const {
            status,
            isBenAdmin,
            openEnrollmentId,
            renewedContractId,
            activeContractId,
        } = this.props

        if (isBenAdmin) return null

        if (status === 'setup') return (
            <CustomContainer padded textAlign='center' style={{paddingTop: '100px', paddingBottom: '100px'}}>
                <div style={{
                    width: '110px',
                    margin: '0 auto 16px',
                    padding: '20px',
                    background: '#fff7ec',
                    borderRadius: '50%'
                }}>
                    <S3Image {...IMAGE.ICON_HOURGLASS} width={'100%'} alt='Open Enrollment has not started yet'/>
                </div>
                <Header as='h4' className='neutral600Text' style={{marginBottom: '16px'}}>
                    Have questions about enrollment progress?
                </Header>
                <p>
                    Reach out to {STRATEGIC_PARTNER.LABEL} concierge at {STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER} or send us a message .<br/>
                    After open enrollment has been completed, we'll reach out to make sure you receive a summary of the enrollment results!
                </p>
            </CustomContainer>
        )

        return (
            <Analytics
                enrollmentEventId={openEnrollmentId}
                renewedContractId={renewedContractId}
                activeContractId={activeContractId}
            />
        )
    }

    render() {
        const {
            status,
            enrollmentStartDate,
            enrollmentEndDate,
            preEnrollmentEducationLaunchDate,
        } = this.props

        return (<>
            <CustomContainer padded>
                <Grid>
                    <Grid.Row only='computer'>
                        <Grid.Column width={1} className={"banner-image"}>
                            <S3Image alt='Calendar' {...IMAGE.ICON_CALENDAR_CIRCLED}/>
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <Grid divided>
                                <Grid.Column width={8}>
                                    <Header as='h4' style={{marginBottom: '8px'}}>{status === 'setup' ? 'Enrollment is set up!' : 'Enrollment has started!'}</Header>
                                    <p>From <b>{enrollmentStartDate}</b> to <b>{enrollmentEndDate}</b></p>
                                </Grid.Column>
                                <Grid.Column width={4} style={{paddingLeft: '2rem'}}>
                                    <Header as='h5' style={{marginTop: '4px', marginBottom: '8px'}}>Launch date</Header>
                                    <p>{preEnrollmentEducationLaunchDate}</p>
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column width={4} textAlign='right'>
                            {this.renderButton()}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row only='computer'>
                        <Grid.Column width={1} className={"banner-image education-link-image"}>
                            {this.renderEducationLinkImage()}
                        </Grid.Column>
                        <Grid.Column width={11}>
                            <ConfirmEligibleEmplyees/>
                            {this.renderLink()}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row only='mobile tablet'>
                        <Grid.Column width={7}>
                            <Header as='h4' style={{marginBottom: '8px'}}>{status === 'setup' ? 'Enrollment is set up!' : 'Enrollment has started!'}</Header>
                            <p>From <b>{enrollmentStartDate}</b> to <b>{enrollmentEndDate}</b></p>
                            <Header as='h5' style={{marginTop: '4px', marginBottom: '8px'}}>Launch date</Header>
                            <p>{preEnrollmentEducationLaunchDate}</p>
                            <ConfirmEligibleEmplyees/>
                            {this.renderLink()}
                        </Grid.Column>
                        <Grid.Column width={5} textAlign='right'>
                            {this.renderButton()}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </CustomContainer>
            {this.renderStatus()}
        </>)
    }
}

export default connect(mapStateToProps)(EditBanner)
