import React, {Component} from "react";
import {Segment, Grid, Icon, Header} from 'semantic-ui-react'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {strFormatMoney} from "../../../utils/utils";
import moment from "moment";
import TotalAmountPendingPaymentSection from "./TotalAmountPendingPaymentSection";
import IMAGE from "../../../constants/images";
import {S3Image} from "../../custom-common";
import RenderNegativeBilling from "./renderNegativeBilling";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        currentInvoices: state.billing.currentInvoices,
        previewInvoice: state.billing.previewInvoice,
        historicalInvoices: state.billing.historicalInvoices,
        accountBalance: state.billing.accountBalance,
        paymentsUnappliedAmount: state.billing.paymentsUnappliedAmount,
        currentBilling: state.billing.currentBilling,
        pastDue: state.billing.pastDue,
        today: state.billing.today,
        isNotAvailableToPay: state.billing.isNotAvailableToPay,
        lastPaymentDate: state.billing.lastPaymentDate,
        lastPaymentAmount: state.billing.lastPaymentAmount,
        isSelfBill: state.billing.isSelfBill
    }
};


class AccountSumaryCard extends Component {
    render() {
        const {currentInvoices, pastDue, accountBalance, previewInvoice, historicalInvoices, isNotAvailableToPay, isSelfBill} = this.props;
        const isZeroPastDue = pastDue === "0.00"
        const isZeroAccountBalance = accountBalance === '0.00'
        const notAvailable = (currentInvoices?.length === 0 && isZeroAccountBalance && isZeroPastDue && !previewInvoice && (!historicalInvoices || (!!historicalInvoices && historicalInvoices.length === 0)));
        const onlyPreview = (currentInvoices?.length === 0 && isZeroAccountBalance && isZeroPastDue && previewInvoice);
        const bkgColor = (notAvailable || onlyPreview) ? "bkgLinen" : "bgLightGray";
        return (
            <Segment loading={isNotAvailableToPay} padded className="segmentContainerRender">
                <Header as="h4">Account summary</Header>
                <Segment className={`segmentRender ${bkgColor}`}>
                    {this.renderWidget(notAvailable, onlyPreview, isZeroAccountBalance, isSelfBill)}
                </Segment>
            </Segment>
        )

    }

    renderWidget(notAvailable, onlyPreview, accountBalanceIsZero, isSelfBill) {
        if (notAvailable) {
            if (isSelfBill) {
                return this.renderNotFirstInvoice()
            }
            return this.renderNotYetAvailable()
        }

        if (onlyPreview) {
            return this.renderNotYetAvailable()
        }

        if(accountBalanceIsZero) {
            return this.renderUpToDate()
        }

        if (this.isNegativeBalance()) {
            return this.renderNegativeBilling()
        }

        return  this.renderBillingData()
    }

    isNegativeBalance() {
        return this.props.accountBalance < 0;
    }

    renderNegativeBilling() {
        const {lastPaymentDate, accountBalance} = this.props;
        return (
            <RenderNegativeBilling
                lastPaymentDate={lastPaymentDate}
                accountBalance={accountBalance}
            />
        )
    }

    renderNotFirstInvoice() {
        return <>
            <Grid textAlign={"center"} style={{paddingLeft: 8, paddingRight: 8}}>
                <Grid.Row>
                    <S3Image {...IMAGE.ICON_HOURGLASS} />
                </Grid.Row>
                <Grid.Row style={{paddingTop: 0, paddingBottom: 0}}>
                    <p style={{textAlign: "center"}}>
                        <span className={"small"}>
                            <b>Your invoice will be finalized at the end of your current billing period.</b>
                        </span>
                    </p>
                </Grid.Row>
            </Grid>
        </>
    }

    renderOnlyPreviewAvailable() {
        const {paymentsUnappliedAmount} = this.props;
        return <React.Fragment>
            <Grid textAlign={"center"} style={{paddingLeft: 8, paddingRight: 8}}>
                <Grid.Row>
                    <S3Image {...IMAGE.ICON_HOURGLASS}/>
                </Grid.Row>
                <Grid.Row style={{paddingTop: 0, paddingBottom: 0}}>
                    <p style={{textAlign: "center"}}><span className={"small"}><b>Your invoice will be finalized at the end of your current billing period.</b></span>
                    </p>
                </Grid.Row>
                {paymentsUnappliedAmount !== "0.00" && <TotalAmountPendingPaymentSection amount={paymentsUnappliedAmount} />}
            </Grid>
        </React.Fragment>
    }

    renderNotYetAvailable() {
        return <React.Fragment>
            <Grid textAlign={"center"} style={{paddingLeft: 8, paddingRight: 8}}>
                <Grid.Row>
                    <S3Image {...IMAGE.ICON_HOURGLASS}/>
                </Grid.Row>
                <Grid.Row style={{paddingTop: 0, paddingBottom: 0}}>
                    <p style={{textAlign: "center"}}><span className={"small"}><b>A preview of your first invoice will be available towards the end of the month.</b></span>
                    </p>
                </Grid.Row>
                <Grid.Row style={{paddingTop: 0, paddingBottom: 0}}>
                    <p style={{textAlign: "center"}}><span className={"small"}><b>You’ll receive an email when it’s ready to view!</b></span>
                    </p>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    }

    renderUpToDate() {
        const {lastPaymentDate} = this.props;
        let mLastPaymentDate = "-";
        if (lastPaymentDate) {
            mLastPaymentDate = moment(lastPaymentDate).format('MMM D, YYYY');
        }
        return <React.Fragment>
            <Grid textAlign={"center"}>
                <Grid.Row>
                    <S3Image {...IMAGE.CHECK_80PX} />
                </Grid.Row>
                <Grid.Row style={{paddingTop: 0, paddingBottom: 0}}>
                    <span className={"small neutral700Text"}><b>Your account is all paid up!</b></span><br/>
                </Grid.Row>
                <Grid.Row style={{paddingTop: 0, paddingBottom: 0}}>
                    <span className={"small neutral600Text"}><i>Last payment date {mLastPaymentDate}</i></span>
                </Grid.Row>
            </Grid>
        </React.Fragment>

    }

    renderBillingData() {
        const {
            currentInvoices,
            accountBalance,
            pastDue,
            currentBilling,
            isNotAvailableToPay,
            lastPaymentDate,
            lastPaymentAmount,
            paymentsUnappliedAmount,
            isSelfBill
        } = this.props;
        return (
            <React.Fragment>
                <Grid columns={1}>
                    {currentBilling !== "0.00" &&
                    <Grid.Column textAlign={"left"}>
                        <Grid columns={2}>
                            <Grid.Column textAlign={"left"} width={8}>
                                <p style={{marginBottom: 0}}><span className={"smaller neutral700Text"}><b>Current invoice</b></span>
                                </p>
                                <span
                                    className={"smaller neutral700Text"}><i>Coverage period: {moment(currentInvoices[0].billingPeriod.startDate).format("MMM D, YYYY")}-{moment(currentInvoices[0].billingPeriod.endDate).format("MMM D, YYYY")}</i></span>
                            </Grid.Column>
                            <Grid.Column textAlign={"right"} width={4}>
                                <Header as="h4">{strFormatMoney(currentBilling)}</Header>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    }
                    {!!pastDue && pastDue !== "0.00" &&
                    <Grid.Column textAlign={"left"} style={{paddingTop: 0, paddingBottom: 0}}>
                        <Grid columns={2}>
                            <Grid.Column textAlign={"left"} width={7} style={{paddingRight: 0}}>
                                <p><span
                                    className={"smaller neutral700Text"}><b>Past due from previous billing period</b></span>
                                </p>
                            </Grid.Column>
                            <Grid.Column textAlign={"right"} width={5}>
                                <Header as="h4">
                                    {!isSelfBill && <Icon color={"red"}
                                                          circular
                                                          inverted
                                                          name="exclamation"
                                                          className="circularIconExclamation" />}
                                    {strFormatMoney(pastDue)}</Header>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    }
                    <Grid.Column textAlign={"right"}>
                        <Grid columns={2}>
                            <Grid.Column textAlign={"left"}>
                                <p><span
                                    className={"small neutral700Text"}><b>Total amount due now</b></span>
                                </p>
                            </Grid.Column>
                            <Grid.Column textAlign={"right"}>
                                <Header as="h2">{strFormatMoney(accountBalance)}</Header>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    {isNotAvailableToPay &&
                        <TotalAmountPendingPaymentSection amount={lastPaymentAmount}
                                                          lastPaymentDate={lastPaymentDate}
                                                          style={{marginLeft: 8, marginRight: 8, position: 'absolute', bottom: 8}}/>
                    }
                    {!isNotAvailableToPay && paymentsUnappliedAmount !== "0.00" &&
                        <TotalAmountPendingPaymentSection amount={paymentsUnappliedAmount}
                                                          style={{marginLeft: 8, marginRight: 8, position: 'absolute', bottom: 8}}/>
                    }
                </Grid>
            </React.Fragment>)
    }
}

export default connect(mapStateToProps)(withRouter(AccountSumaryCard));
