import React, {Component} from 'react'
import moment from 'moment';
import {withRouter} from "react-router-dom";
import {Icon, Message, Segment, Header} from "semantic-ui-react";
import {connect} from "react-redux";
import {documentTypes} from "../../../utils/strings";
import axios from "axios";
import {dowloadBase64, dowloadBlob, resolveErrorMessage, delay} from "../../../utils/utils";
import Concierge from "../../concierge";
import {MailToSupport} from '../../common';
import STRATEGIC_PARTNER from "../../../constants/strategicPartners";
import {CustomGrid, CustomDivider} from '../../custom-common';

const mapStateToProps = (state, ownProps) => {
    const contract = state.portal?.contract?.contract || {}
    const groupSizeSegment = contract?.groupSizeSegment
    return {
        stateCode: contract.issueState,
        groupSizeSegment: groupSizeSegment,
        policyNumber: contract.policyNumber,
        contractId: contract.id,
        ...ownProps,
    }
};

class PolicyDocuments extends Component {
    state = {
        loading: false,
        error: null
    }

    findDocument = (type) => {
        const {data} = this.props

        if (!data.documents?.length) return null

        for (const doc of data.documents) {
            if (doc.documentType === type) {
                return doc
            }
        }
        return null
    }

    findAllDocuments = (type) => {
        const {data} = this.props

        if (!data.documents?.length) return []

        let docs = []
        for (const doc of data.documents) {
            if (doc.documentType === type) {
                docs.push(doc)
            }
        }
        return docs
    }

    downloadDoc = async (doc) => {
        this.setState({loading: true, error: null})
        try {
            const result = await axios.post("/api/employer/v2/policyDocument", {"documentId": doc.documentId}, {headers: {'Content-Type': 'application/json'}});
            const data = result.data;
            dowloadBase64(data.name, data.contentType, data.body)
            this.setState({loading: false})
        } catch (e) {
            console.warn(e)
            this.setState({loading: false, error: "Unable to get the document"})
        }
    }

    downloadCoveragePacket = async () => {
        const { stateCode, groupSizeSegment, policyNumber, contractId } = this.props

        this.setState({loading: true, error: null})

        try {
            const payload = {
                contractId,
                stateCode,
                groupSizeSegment,
                policyNumber,
            }

            let result
            let status

            result = await axios.post('/api/employer/v2/generateCoveragePacket', payload)
            status = result.data.status

            while (status === 'pending') {
                await delay(4000)
                result = await axios.post('/api/employer/v2/isCoveragePacketGenerated', payload)
                status = result.data.status
            }

            if (status !== 'success') return this.setState({
                loading: false,
                error: 'Unable to generate the document'
            })

            result = await axios.post('/api/employer/v2/downloadCoveragePacket', payload, { responseType: 'blob' })
            let packetName = result.headers["x-packetname"];
            if(!packetName){
                const date = moment().format('YYYY-MM-DD')
                packetName = `coverage-packet-${date}.pdf`;
            }
            const data = result.data
            dowloadBlob(packetName, 'application/pdf', data)
            this.setState({ loading: false })
        } catch (e) {
            this.setState({
                loading: false,
                error: resolveErrorMessage(e, 'Unable to generate the document')
            })
        }
    }

    manageConciergeModal = (value) => {
        this.setState({conciergeOpen: value})
    }

    get downloadIcon() {
        return (
            <Icon
                name='download'
                className="downloadIconColor downloadIcon"
            />
        )
    }

    get pdfIcon() {
        return (
            <Icon
                name="file pdf outline"
                color="red"
                size="large"
                style={{ position: 'absolute', left: '1.5rem', top: '2rem' }}
            />
        )
    }

    render() {
        const {loading, error, conciergeOpen} = this.state
        const groupAppDoc = this.findDocument(documentTypes.groupApplication)
        return (
            <React.Fragment>
                <Header as="h4">Policy documents</Header>
                <p className={"small"}>
                    If you are unable to access / download your policy documents
                    reach out to <MailToSupport /> to request a paper copy.
                </p>
                {error && <Message error> {error}</Message>}
                <CustomGrid basic stackable columns={2} as={Segment} loading={loading}>
                    <CustomGrid.Column width={7} style={{ paddingRight: '2rem' }}>
                        {groupAppDoc && (
                            <Segment className={"hovereable clickable"} onClick={() => {
                                this.downloadDoc(groupAppDoc)
                            }}>
                                {this.pdfIcon}
                                <Segment basic compact vertical style={{ marginLeft: '48px'}}>
                                    <b>Group application</b>{this.downloadIcon}
                                    <p className={"smaller truncate-3"} style={{ minHeight: '40px', marginTop: '8px', width: '90%' }}>
                                        The approved limited benefit policyholder application submitted for your group's policy.
                                    </p>
                                </Segment>
                            </Segment>
                        )}
                        <Segment className={"hovereable clickable"} onClick={this.downloadCoveragePacket}>
                            {this.pdfIcon}
                            <Segment basic compact vertical style={{ marginLeft: '48px'}}>
                                <b>Coverage packet</b>{this.downloadIcon}
                                <p className={"smaller truncate-3"} style={{ minHeight: '40px', marginTop: '8px', width: '90%' }}>
                                    This includes the limited benefit insurance policy issued to your group,
                                    the limited benefit insurance certificate and more.
                                </p>
                            </Segment>
                        </Segment>
                    </CustomGrid.Column>
                    <CustomGrid.Column width={5}>
                        <div className="dashed-vertical-divider" />
                        <Segment basic style={{ marginTop: 0 }}>
                            {this.pdfIcon}
                            <Segment basic compact vertical style={{ marginLeft: '48px'}}>
                                <b>Need a claim form for a member?</b>
                                <div className={"smaller"} style={{ margin: '8px 0', width: '234px' }}>
                                    The claim form and instructions for submitting member claims under your group's policy.<br />
                                    <br />
                                    Don't forget members can file a claim in minutes with our online Member Portal or mobile app. <br />
                                </div>
                                <a
                                    href={STRATEGIC_PARTNER.PAPER_CLAIMS_SUBMISSION_FORM_PDF}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ fontSize: '14px' }}
                                >
                                    <Icon name='download' className="downloadIconColor" /> Get it here
                                </a>
                            </Segment>
                        </Segment>
                    </CustomGrid.Column>
                </CustomGrid>
                <CustomDivider hidden/>
                <Concierge open={conciergeOpen} onManage={this.manageConciergeModal} hasDedicatedConcierge/>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(PolicyDocuments));
