import {Modal, Button} from "semantic-ui-react";
import React from "react";
import {CustomModalHeader} from "../../../custom-common";

export const TerminationConfirmationModal = ({
                                                 onConfirm,
                                                 onCancel,
                                                 headerText = 'Confirmation',
                                                 contentText = '',
                                                 cancelText = 'Cancel',
                                                 confirmText = 'Confirm'
                                             }) => (
    <Modal open onClose={onCancel} style={{maxWidth: '525px'}}>
        <CustomModalHeader title={headerText} onClose={onCancel}/>
        <Modal.Content>
            <p style={{whiteSpace: 'pre-line'}}>{contentText}</p>
        </Modal.Content>
        <Modal.Actions>
            <Button basic color="grey" onClick={onCancel}>{cancelText}</Button>
            <Button primary onClick={onConfirm}>{confirmText}</Button>
        </Modal.Actions>
    </Modal>
);
