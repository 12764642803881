import React, {Component} from 'react';
import {Button, Container, Form, Grid, Input, Message, Header} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import axios from "axios";
import Footer from "./footer";
import PrePortalHeader from "./prePortalHeader";
import {resolveErrorMessage} from "../utils/utils";
import {CustomDivider} from './custom-common';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            confirmPassword: '',
            passwordError: null,
            requesting: false,
            submitError: null,
            confirmPasswordError: null
        };
    }

    async componentDidMount() {
        try {
            this.setState({requesting: true, submitError: null});
            const token = new URLSearchParams(window.location.search).get("token");
            const payload = {token}
            await axios.post("/api/employer/v2/prepareResetPassword", payload, {headers: {'Content-Type': 'application/json'}});
            this.setState({requesting: false});
        } catch (e) {
            const error = resolveErrorMessage(e, "Invalid token")
            if (error === "Invalid token") {
                this.props.history.replace({pathname: "/forgotPassword", state: {resetLink: true}});
            }

            this.setState({
                requesting: false,
                submitError: error
            });
        }
    }

    handleChange = (e, {name, value}) => this.setState({[name]: value, error: ''})

    handleSubmit = async (e) => {
        e.preventDefault();
        const errors = this.validate();
        if (errors) {
            return
        }
        this.setState({requesting: true, submitError: null});
        try {
            const token = new URLSearchParams(window.location.search).get("token");
            const payload = {token: token, password: this.state.password}
            await axios.post("/api/employer/v2/resetPassword", payload, {headers: {'Content-Type': 'application/json'}});
            this.setState({requesting: false, submitError: null});
            this.props.history.replace("/login");
        } catch (e) {
            const error = resolveErrorMessage(e, "Invalid token")
            if (error === "Invalid token") {
                this.props.history.replace({pathname: "/forgotPassword", state: {resetLink: true}});
            }

            this.setState({
                requesting: false,
                submitError: error
            });
        }
    }

    validate = () => {
        let isError = false;
        const {password} = this.state;
        const invalidPassword = (password.length < 10 || password.search(/[A-Z]/) < 0) || password.match(/\d+/g) < 1;
        if (invalidPassword) {
            isError = true;
            this.setState({
                passwordError: "Passwords must contain at least 10 characters, one uppercase letter, and one number.",
                success: false
            })
        }
        return isError;
    }

    validatePasswordMatch = () => {
        let isError = false;
        const {password, confirmPassword} = this.state
        if (password !== confirmPassword) {
            this.setState({
                confirmPasswordError: "Your passwords do not match. Please try again."
            });
        }
        return isError;
    }

    render() {
        const {password, passwordError, confirmPassword, requesting, submitError, confirmPasswordError} = this.state;
        const token = new URLSearchParams(window.location.search).get("token");

        return (
            <React.Fragment>
                <PrePortalHeader/>
                <div className="employer-portal-container" style={{background: 'white'}}>
                    {token ? (
                        <Grid container stackable columns={1} centered style={{marginTop: 0}}>
                            <Grid.Column className={"pageContent"}>
                                <Grid centered columns={1}>
                                    <Grid.Column style={{marginTop: '4em'}} width={5}>
                                        <Header as='h1' textAlign='center'>Reset password</Header>
                                        <p style={{textAlign: 'center'}} className={"neutral600Text"}>Passwords must
                                            contain at
                                            least 10 characters,
                                            one uppercase letter, and one number.</p>
                                    </Grid.Column>
                                    <Grid.Row>
                                        <Grid.Column width={4}>
                                            <Form>
                                                <Form.Field error={!!passwordError} required>
                                                    <label>Enter password</label>
                                                    <Input
                                                        fluid type="password"
                                                        name='password'
                                                        value={password}
                                                        onChange={this.handleChange}
                                                        onFocus={() => {
                                                            this.setState({passwordError: null});
                                                        }}
                                                        onBlur={(e) => {
                                                            const val = e.target.value;
                                                            this.validate(val);
                                                        }}
                                                    />
                                                    {!!passwordError &&
                                                    <span className={"warningRedText smaller"}>{passwordError}</span>}
                                                </Form.Field>

                                                <Form.Field error={!!confirmPasswordError} required>
                                                    <label>Re-enter password</label>
                                                    <Input fluid type="password"
                                                           name='confirmPassword'
                                                           value={confirmPassword}
                                                           onFocus={() => {
                                                               this.setState({confirmPasswordError: null});
                                                           }}
                                                           onBlur={() => {this.validatePasswordMatch();}}
                                                           onChange={this.handleChange}/>
                                                    {!!confirmPasswordError &&
                                                    <span
                                                        className={"warningRedText smaller"}>{confirmPasswordError}</span>}
                                                </Form.Field>

                                                    {!!submitError &&
                                                    <Form.Field><Message negative>{submitError}</Message></Form.Field>}

                                                    <Form.Field>
                                                        <Button
                                                            onClick={this.handleSubmit}
                                                            primary
                                                            disabled={password.length < 10 || !!passwordError || confirmPassword !== password || requesting}
                                                            loading={requesting}
                                                        >
                                                            Save
                                                        </Button>
                                                    </Form.Field>
                                            </Form>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid>
                        ) : (
                        <Container>
                        <CustomDivider hidden/>
                        <Message negative>Missing token</Message>
                        </Container>
                        )}
                        </div>
                        <Footer/>
                        </React.Fragment>
                        )
                        }
                        }

                        export default withRouter(connect()(ResetPassword));