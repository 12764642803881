import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Grid, Loader, Message, Segment, Table, Header, Button} from "semantic-ui-react";
import {connect} from "react-redux";
import Concierge from "../../../concierge";
import PortalModalHeader from "../../portalModalHeader";
import {setPrevRoute} from "../../../../actions/portalModalHeaderActions";
import {convertDisplayDateFormatToServer, getTerminationStepProps} from "../../../../utils/common";
import Steps from "../../../steps/steps";
import Footer from "../../../footer";
import {TerminationConfirmationModal} from "../common/terminationConfirmationModal";
import axios from "axios";
import {resolveErrorMessage} from "../../../../utils/utils";
import {EmploymentTerminationCard} from "./partials/employmentTerminationCard";
import {setTerminationDetails} from "../../../../actions/employeeActions";
import {setTotalActiveEmployeesCount, setTotalInactiveEmployeesCount} from "../../../../actions/employeesActions";
import moment from "moment/moment";
import CancelButton from "../common/cancelButton";

const mapStateToProps = (state, ownProps) => {
    const {employeeTermination, employees} = state;
    return {
        employees,
        employeeTermination,
        ...ownProps,
    }
};

const mapDispatchToProps = dispatch => ({
    setPrevRoute: portalModalHeader => dispatch(setPrevRoute(portalModalHeader)),
    setTerminationDetails: employeeTermination => dispatch(setTerminationDetails(employeeTermination)),
    setTotalActiveEmployeesCount: employees => dispatch(setTotalActiveEmployeesCount(employees)),
    setTotalInactiveEmployeesCount: employees => dispatch(setTotalInactiveEmployeesCount(employees)),
})

class EmploymentTerminationReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            conciergeOpen: false,
            contactId: null,
            error: null,
            loading: true,
            showModal: {confirmation: false, cancelation: false},
            stepProps: null,
            confirmingTermination: false,
        };
    }

    async componentDidMount() {
        window.onbeforeunload = function (event) {
            event.preventDefault();
            event.returnValue = '';
        };
        this.buildSteps();
        const {match, setPrevRoute, setTerminationDetails, employeeTermination: {reason, terminationDate}} = this.props;
        const id = match.params.id;
        const payload = {employeeId: id, terminationReason: reason, terminationDate: convertDisplayDateFormatToServer(terminationDate)};
        setPrevRoute({prevRoute: `/portal/employee/${id}/employment-termination`});
        try {
            const response = await axios.post("/api/employer/v2/submitEmployeeTerminationDetails", payload, {headers: {'Content-Type': 'application/json'}});
            if (response.status !== 200) {
                throw Error(response.statusText);
            }
            setTerminationDetails(response.data);
            this.setState({loading: false});
        } catch (e) {
            this.setState({loading: false, error: resolveErrorMessage(e, "Unable to submit employee termination details")});
        }
    }

    componentWillUnmount() {
        window.onbeforeunload = null;
    }

    buildSteps = () => {
        this.setState({stepProps: getTerminationStepProps(1)});
    }

    manageConciergeModal = (value) => {
        this.setState({conciergeOpen: value})
    }

    handleConfirmCancelation = () => {
        const {match, history} = this.props;
        const id = match.params.id;
        history.push(`/portal/employee/${id}`)
    }

    handleConfirm = async () => {
        this.setState({showModal: {confirmation: false}, confirmingTermination: true});
        const {match, setTerminationDetails, employeeTermination, history, employees, setTotalActiveEmployeesCount, setTotalInactiveEmployeesCount} = this.props;
        const {reason, terminationDate, coverageEndDate, rosterMemberId} = employeeTermination;
        const {totalActiveCount, totalInactiveCount} = employees;
        const id = match.params.id;
        const payload = {
            coverageEndDate,
            employeeId: rosterMemberId,
            terminationDate: convertDisplayDateFormatToServer(terminationDate),
            terminationReason: reason,
        };
        try {
            const response = await axios.post("/api/employer/v2/terminateEmployement", payload, {headers: {'Content-Type': 'application/json'}});
            if (response.status !== 200) {
                throw new Error(response.statusText);
            }
            setTerminationDetails({...response.data, employeeId: id});
            const now = moment();
            if (moment(terminationDate).isBefore(now)) {
                setTotalActiveEmployeesCount(totalActiveCount - 1)
                setTotalInactiveEmployeesCount(totalInactiveCount + 1)
            }
            history.push({pathname: `/portal/employee/${id}/employment-termination/success`});
        } catch (e) {
            this.setState({confirmingTermination: false, showModal: {confirmation: false}, error: resolveErrorMessage(e, "Unable to terminate employment")});
        }
    }

    handleModalClose = (modal) => {
        this.setState({showModal: {[modal]: false}})
    }

    renderCancelationModal = () => {
        return (
            <TerminationConfirmationModal
                cancelText="No, go back"
                confirmText="Yes, cancel"
                contentText={`Are you sure you want to cancel this change request?\nProgress will not be saved.`}
                headerText="Cancel progress?"
                onCancel={() => this.handleModalClose('cancelation')}
                onConfirm={this.handleConfirmCancelation}
            />
        )
    }

    renderConfirmationModal = () => {
        return (
            <TerminationConfirmationModal
                confirmText="Yes, terminate"
                contentText="Are you sure you want to terminate this employee? There is no undo."
                headerText="Confirm termination?"
                onCancel={() => this.handleModalClose('confirmation')}
                onConfirm={this.handleConfirm}
            />
        )
    }

    onClick = (modal) => {
        this.setState({showModal: {[modal]: true}})
    }

    renderAffectedMembers() {
        const {employeeTermination: {members}} = this.props;
        if (!members.length) {
            return null;
        }
        const mNow = moment(Date.now());
        return (
            <Segment className={'termination-planChanges'}>
                <Header as={"h3"} style={{marginBottom: '8px'}}>Plan changes</Header>
                <span>Coverage is ending for the following member(s)—</span>
                <Table padded style={{width: '277px'}}>
                    <Table.Body>
                        {members.map((d, idx) => {
                            const mDate = moment(d.birthDate)
                            const age = mNow.diff(mDate, "years")
                            return (
                                <Table.Row key={idx} verticalAlign={"middle"}>
                                    <Table.Cell className={"small"} style={{paddingLeft: 0}}><b>{`${d.firstName} ${d.lastName}`}</b></Table.Cell>
                                    <Table.Cell centered className={"small"}>{d.relToEmployee}</Table.Cell>
                                    <Table.Cell className={"small"}>{age}</Table.Cell>
                                </Table.Row>)
                        })}
                    </Table.Body>
                </Table>
            </Segment>
        )
    }

    render() {
        const {
            conciergeOpen,
            error,
            loading,
            showModal: {confirmation, cancelation},
            stepProps,
            confirmingTermination,
        } = this.state;

        const confirmButton = <Button data-qa_ee_termination_employ_submit onClick={() => this.onClick('confirmation')} primary disabled={loading}>Submit</Button>;
        const cancelButton = <CancelButton data-qa_ee_termination_employ_cancel onCancel={() => this.onClick('cancelation')} />;

        return (
            <>
                <PortalModalHeader modalTitle={'Employment termination'} showRightMenu/>
                <div className="employer-portal-container" style={{background: 'white'}}>

                    {confirmingTermination ? <Loader active/> : <>
                        {stepProps && !error && <Grid stackable>
                            <Grid.Column>
                                <Steps stepProps={stepProps} customStyle={{marginTop: '61px', marginLeft: '-138px', marginBottom: 0}} lineWidth={{width: '153px'}}/>
                            </Grid.Column>
                        </Grid>}
                        <Grid container stackable columns={1} centered>
                            <Grid.Column className={"pageContent"} style={{width: "668px"}}>
                                <Segment padded loading={loading} basic>
                                    {error ? <Message negative>{error}</Message> : loading ? null : <>
                                        <Header as={"h2"} style={{marginBottom: '8px'}}>Review and confirm</Header>
                                        <span className={'termination-review-subTitle'}>Review and confirm the information below is correct—</span>
                                        <EmploymentTerminationCard/>
                                        {this.renderAffectedMembers()}
                                    </>}
                                </Segment>
                            </Grid.Column>
                        </Grid>
                    </>}


                </div>
                <Footer button={confirmButton} link={cancelButton} showTopFooter={!error && !confirmingTermination} hasToDisplayCarrierDisclaimer />
                <Concierge open={conciergeOpen} onManage={this.manageConciergeModal} hasDedicatedConcierge/>
                {cancelation && this.renderCancelationModal()}
                {confirmation && this.renderConfirmationModal()}
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EmploymentTerminationReview));
