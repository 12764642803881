import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Button, Grid, Icon, Header} from 'semantic-ui-react'
import {parseDate} from '../../../utils/date'
import EducationExperienceLinks from './educationExperienceLinks';
import Analytics from "./analytics";
import STRATEGIC_PARTNER from "../../../constants/strategicPartners";
import {S3Image, CustomContainer} from "../../custom-common";
import IMAGE from "../../../constants/images";

const mapStateToProps = (state, ownProps) => {
    const oe = state.openEnrollment || {}

    return {
        ...ownProps,
        status: oe.status,
        enrollmentStartDate: parseDate(oe.enrollmentStartDate),
        enrollmentEndDate: parseDate(oe.enrollmentEndDate),
        isBenAdmin: state.portal.isBenAdmin,
        openEnrollmentId: oe.openEnrollmentId,
        renewedContractId: oe.renewedContract.contractId,
        activeContractId: state.portal.contract.contract.id,
    }
}

class FinishedBanner extends Component {
    handleEditClick = () => {
        const {onClick} = this.props

        onClick && onClick()
    }

    renderButton = () => {
        const {status} = this.props

        if (status === 'closed') return

        return (
            <Button basic primary compact
                    style={{fontSize: '14px', marginTop: '12px', whiteSpace: 'nowrap'}}
                    onClick={this.handleEditClick}
            >Edit enrollment settings <Icon name="pencil" style={{marginLeft: '1em'}}/></Button>
        )
    }

    renderComputerEducationLinkSection = () => {
        return <Grid.Row only='computer'>
            <Grid.Column width={2} className={"banner-image"}>
                <S3Image {...IMAGE.PEACE_OF_MIND_PAYMENT_147PX} alt='Education Link Image'/>
            </Grid.Column>
            <Grid.Column width={10} style={{paddingTop: '24px'}}>
                <EducationExperienceLinks style={{marginTop: '2rem'}}/>
            </Grid.Column>
        </Grid.Row>
    }

    render() {
        const {isBenAdmin, enrollmentStartDate, enrollmentEndDate, openEnrollmentId, renewedContractId, activeContractId} = this.props
        return (<>
            <CustomContainer padded>
                <Grid>
                    <Grid.Row only='computer'>
                        <Grid.Column width={2}>
                            <S3Image {...IMAGE.SUCCESS_CONFETTI_120PX} alt='Open Enrollment has finished'/>
                        </Grid.Column>
                        <Grid.Column width={10} style={{paddingTop: '24px'}}>
                            <Grid>
                                <Grid.Column width={8}>
                                    <Header as='h4' style={{marginBottom: '8px'}}>Enrollment has ended!</Header>
                                    <p>From <b>{enrollmentStartDate}</b> to <b>{enrollmentEndDate}</b></p>
                                </Grid.Column>
                                <Grid.Column width={4} textAlign='right'>
                                    {this.renderButton()}
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                    {isBenAdmin && this.renderComputerEducationLinkSection()}
                    <Grid.Row only='mobile tablet'>
                        <Grid.Column width={7}>
                            <Header as='h4' style={{marginBottom: '8px'}}>Enrollment has ended!</Header>
                            <p>From <b>{enrollmentStartDate}</b> to <b>{enrollmentEndDate}</b></p>
                            {isBenAdmin && <EducationExperienceLinks style={{marginTop: '2rem'}}/>}
                        </Grid.Column>
                        <Grid.Column width={5} textAlign='right'>
                            {this.renderButton()}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </CustomContainer>
            {isBenAdmin
                ? <CustomContainer padded textAlign='center' style={{paddingTop: '100px', paddingBottom: '100px'}}>
                    <S3Image {...IMAGE.ICON_MAP} alt='Open Enrollment has not started yet' style={{margin: '0 auto 16px'}}/>
                    <Header as='h4' style={{marginBottom: '16px'}}>
                        Next steps
                    </Header>
                    <p style={{maxWidth: '584px', margin: '16px auto'}}>
                        Your open enrollment has ended, please provide us with an updated election census as soon as possible.
                    </p>
                    <p>
                        <b>Need help?</b> Reach out to your {STRATEGIC_PARTNER.LABEL} Account Manager with any questions!
                    </p>
                </CustomContainer>
                : <Analytics
                    hasToDisplayReachOutMessage
                    enrollmentEventId={openEnrollmentId}
                    renewedContractId={renewedContractId}
                    activeContractId={activeContractId}
                />
            }
        </>)
    }
}

export default connect(mapStateToProps)(FinishedBanner)
