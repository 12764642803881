import {groupActionTypes, authActionTypes} from "../../actions/actionTypes";

const initialState = {
    taxId: '',
    legalEntityName: null,
    dbaName: null,
    address: null,
    zipcode: null,
    city: null,
    state: null,
    brellaEnrollment: null,
    affiliatesSubsidiaries: '',
    stateValue: '',
    variesByClass: null,
    ratedClasses: [],
    newEligibilityInfo: {
        benefitTerminationRules: null,
        activelyAtWorkHours: null,
        onlyUnmarriedChildren: null,
        civilUnionEligible: null,
        domesticPartnerEligible: null,
        subjectToERISA: null,
        cobra: null,
    }
};

export const groupReducer = (state = initialState, action) => {
    switch (action.type) {
        case groupActionTypes.SET_INITIAL_GROUP_DATA:
            let ratedClasses = []
            action.payload.ratedClasses.forEach(item => {
                const groupClass = {
                    benefitTerminationRules: item.contractTerms.eligibility.benefitTerminationRules,
                    activelyAtWorkHours: item.contractTerms.eligibility.activelyAtWorkHours,
                    onlyUnmarriedChildren: item.contractTerms.eligibility.onlyUnmarried,
                    civilUnionEligible: item.contractTerms.eligibility.civilUnionEligible,
                    domesticPartnerEligible: item.contractTerms.eligibility.domesticPartnerEligible,
                    subjectToERISA: item.contractTerms.eligibility.subjectToERISA,
                    groupClass: item.groupClass,
                    employerContributions: item.employerContributions,
                    ratedClassRates: item.ratedClassRates,
                    ageRanges: item.contractTerms.ages,
                    contractTerms: item.contractTerms,
                    contributionType: item.contributionType,
                    cobra: item.contractTerms.eligibility.cobra,
                }
                ratedClasses.push(groupClass)
            })

            return {
                ...state,
                taxId: action.payload.employer.taxId,
                legalEntityName: action.payload.employer.legalEntityName,
                address: action.payload.employer.address.street,
                zipcode: action.payload.employer.address.zipCode,
                city: action.payload.employer.address.city,
                state: action.payload.employer.address.state,
                stateValue: action.payload.employer.address.stateObject.value,
                brellaEnrollment: action.payload.quote.useClaraEnrollment,
                affiliatesSubsidiaries: action.payload.employer.proposalAffiliatesSubsidiaries,
                ratedClasses: ratedClasses,
                dbaName: action.payload.employer.dbaName,
                civilUnionDefaultValue: action.payload.quote.civilUnionDefaultValue,
                domesticPartnerDefaultValue: action.payload.quote.domesticPartnerDefaultValue,
            };
        case groupActionTypes.SET_TAX_ID:
            return {
                ...state,
                taxId: action.payload
            };
        case groupActionTypes.SET_LEGAL_ENTITY_NAME:
            return {
                ...state,
                legalEntityName: action.payload
            };
        case groupActionTypes.SET_DBA_NAME:
            return {
                ...state,
                dbaName: action.payload
            };
        case groupActionTypes.SET_ADDRESS:
            return {
                ...state,
                address: action.payload
            };
        case groupActionTypes.SET_ZIP_CODE:
            return {
                ...state,
                zipcode: action.payload
            };
        case groupActionTypes.SET_CITY:
            return {
                ...state,
                city: action.payload
            };
        case groupActionTypes.SET_STATE:
            return {
                ...state,
                state: action.payload.text,
                stateValue: action.payload.value
            };
        case groupActionTypes.SET_AFFILIATES:
            return {
                ...state,
                affiliatesSubsidiaries: action.payload
            };
        case groupActionTypes.SET_BENEFIT:
            return {
                ...state,
                newEligibilityInfo: {
                    ...state.newEligibilityInfo,
                    benefitTerminationRules: action.payload
                }
            };
        case groupActionTypes.SET_ACTIVELY_AT_WORK_HOURS:
            return {
                ...state,
                newEligibilityInfo: {
                    ...state.newEligibilityInfo,
                    activelyAtWorkHours: action.payload
                }
            };
        case groupActionTypes.SET_UNMARRIED:
            return {
                ...state,
                newEligibilityInfo: {
                    ...state.newEligibilityInfo,
                    onlyUnmarriedChildren: action.payload
                }
            };
        case groupActionTypes.SET_DOMESTIC_PARTNER_ELIGIBLE:
            return {
                ...state,
                newEligibilityInfo: {
                    ...state.newEligibilityInfo,
                    domesticPartnerEligible: action.payload
                }
            };
        case groupActionTypes.SET_CIVIL_UNION_ELIGIBLE:
            return {
                ...state,
                newEligibilityInfo: {
                    ...state.newEligibilityInfo,
                    civilUnionEligible: action.payload
                }
            };
        case groupActionTypes.SET_SUBJECT_ERISA:
            return {
                ...state,
                newEligibilityInfo: {
                    ...state.newEligibilityInfo,
                    subjectToERISA: action.payload
                }
            };
        case groupActionTypes.SET_COBRA:
            return {
                ...state,
                newEligibilityInfo: {
                    ...state.newEligibilityInfo,
                    cobra: action.payload
                }
            };
        case groupActionTypes.UPDATE_CLASS:
            return {
                ...state,
                ratedClasses: state.ratedClasses.map((item) => item.groupClass.id === action.payload.id ? {...item, [action.payload.fieldName]: action.payload.value} : item),
            };
        case groupActionTypes.SET_BRELLA_ENROLLMENT:
            return {
                ...state,
                brellaEnrollment: action.payload
            };
        case groupActionTypes.SET_VARIES_BY_CLASS:
            return {
                ...state,
                variesByClass: action.payload
            };
        case authActionTypes.LOGOUT:
            return {
                taxId: '',
                legalEntityName: '',
                address: '',
                zipcode: '',
                city: '',
                state: '',
                brellaEnrollment: null,
                affiliatesSubsidiaries: '',
                stateValue: '',
                variesByClass: null,
                ratedClasses: [],
                newEligibilityInfo: {
                    benefitTerminationRules: null,
                    activelyAtWorkHours: null,
                    onlyUnmarriedChildren: null,
                    civilUnionEligible: null,
                    domesticPartnerEligible: null,
                    subjectToERISA: null,
                }
            };
        default:
            return state
    }
};
