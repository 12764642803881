import React, {Component} from 'react';
import {Container, Header} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import {CustomDivider} from './custom-common';

class TermsOfService extends Component {
    render() {
        return (
            <div className="employer-main-container">
            <Container text>
                <Header as='h1'>Terms of Service</Header>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Velit scelerisque in dictum non consectetur a erat. Lectus quam id leo in
                    vitae turpis. Ut sem nulla pharetra diam sit amet nisl suscipit. Viverra tellus in hac habitasse
                    platea dictumst vestibulum rhoncus. Nisi quis eleifend quam adipiscing vitae proin sagittis nisl
                    rhoncus. Quis imperdiet massa tincidunt nunc pulvinar sapien et. Viverra mauris in aliquam sem
                    fringilla ut morbi tincidunt. Sed id semper risus in hendrerit gravida rutrum quisque. Tortor
                    consequat id porta nibh. Consequat nisl vel pretium lectus quam. Egestas congue quisque egestas diam
                    in arcu cursus. Etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus. At in
                    tellus integer feugiat scelerisque varius morbi enim. Sed ullamcorper morbi tincidunt ornare massa
                    eget.

                    Massa sapien faucibus et molestie ac. Orci sagittis eu volutpat odio facilisis. Tellus in metus
                    vulputate eu scelerisque felis. Sed vulputate odio ut enim blandit volutpat maecenas volutpat. Proin
                    sagittis nisl rhoncus mattis. Lectus vestibulum mattis ullamcorper velit. Libero nunc consequat
                    interdum varius sit amet mattis vulputate. Velit egestas dui id ornare arcu. Semper eget duis at
                    tellus at urna condimentum. Bibendum neque egestas congue quisque egestas diam in. Egestas maecenas
                    pharetra convallis posuere. Tincidunt tortor aliquam nulla facilisi cras. Hendrerit gravida rutrum
                    quisque non tellus orci ac auctor. Massa sed elementum tempus egestas. Convallis aenean et tortor at
                    risus viverra adipiscing at in. Curabitur vitae nunc sed velit dignissim. Fermentum iaculis eu non
                    diam phasellus. Risus pretium quam vulputate dignissim. Placerat orci nulla pellentesque dignissim
                    enim. Tortor dignissim convallis aenean et tortor at risus viverra.
                </p>
                <CustomDivider hidden/>

                <Header as='h2'>A heading</Header>

                <p>A Quisque id diam vel quam elementum pulvinar etiam. Tristique nulla aliquet enim tortor at auctor
                    urna. Non sodales neque sodales ut etiam. Vel risus commodo viverra maecenas accumsan lacus vel. Vel
                    turpis nunc eget lorem dolor sed viverra. Aliquam sem fringilla ut morbi tincidunt augue. Cursus in
                    hac habitasse platea. Sed tempus urna et pharetra pharetra massa massa. Aenean pharetra magna ac
                    placerat. Adipiscing bibendum est ultricies integer quis. Euismod nisi porta lorem mollis aliquam ut
                    porttitor leo a. Ut consequat semper viverra nam libero justo laoreet sit amet. Amet justo donec
                    enim diam vulputate ut. Sit amet consectetur adipiscing elit ut aliquam.</p>

            </Container>
            </div>
        )
    }
}

export default withRouter(TermsOfService);