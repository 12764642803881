import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Grid, Icon, Header} from 'semantic-ui-react';
import {parseDate} from '../../../utils/date';
import ConfirmEligibleEmplyees from './confirmEligibleEmplyees';
import STRATEGIC_PARTNER from "../../../constants/strategicPartners";
import IMAGE from "../../../constants/images";
import {S3Image, CustomContainer} from "../../custom-common";

const mapStateToProps = (state, ownProps) => {
    const oe = state.openEnrollment || {renewedContract: {}}

    return {
        ...ownProps,
        policyEffectiveDate: oe.renewedContract.policyEffectiveDate,
        isEdit: !!(
            oe.preEnrollmentEducationLaunchDate
            && oe.enrollmentStartDate
            && oe.enrollmentEndDate
        ),
    }
}

class SetupBanner extends Component {
    handleEditClick = () => {
        const {onClick} = this.props

        onClick && onClick()
    }

    renderButton = props => {
        const {disabled, isEdit} = this.props

        const style = props?.style || {}

        const styles = {
            ...style,
            fontSize: '14px',
            marginTop: '12px',
            whiteSpace: 'nowrap',
        }

        if (isEdit) return (
            <Button basic primary compact
                    onClick={this.handleEditClick}
                    {...props}
                    style={styles}
            >Edit enrollment settings <Icon name="pencil" style={{marginLeft: '1em'}}/></Button>
        )

        return (
            <Button primary compact
                    onClick={this.handleEditClick}
                    disabled={disabled}
                    {...props}
                    style={styles}
            >Set up enrollment</Button>
        )
    }

    effectiveDateText = () => {
        const {policyEffectiveDate} = this.props

        return (
            <p className={"neutral700Text"} style={{maxWidth: '550px'}}>
                {policyEffectiveDate ? (
                    ` It's that time of the year again! Your ${STRATEGIC_PARTNER.LABEL} open enrollment for coverage effective ${parseDate(policyEffectiveDate)} needs to be configured. `
                ) : (
                    ` It's that time of the year again! Your ${STRATEGIC_PARTNER.LABEL} open enrollment needs to be configured. `
                )}
            </p>
        )
    }

    render() {

        return (
            <CustomContainer padded>
                <Grid>
                    <Grid.Column width={2} only='computer' className={"banner-image"}>
                        <S3Image alt='Calendar' {...IMAGE.ICON_CALENDAR}/>
                    </Grid.Column>
                    <Grid.Column computer={10} tablet={12} mobile={12}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column computer={9} tablet={12} mobile={12}>
                                    <Header as='h3' style={{marginTop: '4px', marginBottom: '16px'}}>Let's get your enrollment set up—</Header>
                                    {this.effectiveDateText()}
                                </Grid.Column>
                                <Grid.Column width={3} only='computer'>
                                    {this.renderButton({style: {float: 'right'}})}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row only='tablet mobile'>
                                <Grid.Column width={12}>
                                    {this.renderButton()}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={12}>
                                    <ConfirmEligibleEmplyees/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid>
            </CustomContainer>
        )
    }
}

export default connect(mapStateToProps)(SetupBanner)
