import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Button, Form, Input, Message, Modal, Header} from "semantic-ui-react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import Radio from "semantic-ui-react/dist/commonjs/addons/Radio";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import axios from "axios";
import {setBillingData, setIsNotAvailableToPay} from "../../../actions/billingActions";
import moment from "moment";
import {formatMoneyFixedTo2, strFormatMoney} from "../../../utils/utils";
import NumberFormat from 'react-number-format';
import STRATEGIC_PARTNER from "../../../constants/strategicPartners";
import {ClickableText, CustomDivider, CustomModalHeader, CustomGrid} from "../../custom-common";
import {UploadFile} from "../../forms/uploadFile";
import {readFileAsync} from "../../../utils/common";
import {show as showToast} from "../../../actions/toastActions";
import RenderNegativeBilling from "./renderNegativeBilling";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        historicalInvoices: state.billing.historicalInvoices,
        today: state.billing.today,
        bankAccounts: state.bankAccount.bankAccounts,
        lastPaymentDate: state.billing.lastPaymentDate,
        isNotAvailableToPay: state.billing.isNotAvailableToPay,
        isLastPaymentFailed: state.billing.isLastPaymentFailed,
        isLastPaymentSucceeded: state.billing.isLastPaymentSucceeded,
        paymentsUnappliedAmount: state.billing.paymentsUnappliedAmount,
        lastPaymentAmount: state.billing.lastPaymentAmount,
        isSelfBill: state.billing.isSelfBill
    }
};

class PaymentAuthorizationCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedBankAccount: null,
            amountOptionSelected: props.isSelfBill ? "other" : "fullAmount",
            customPaymentAmount: null,
            disableMakePaymentButton: props.isSelfBill,
            paymentInProgress: false,
            requiredAmount: false,
            files: [],
            isCustomPaymentAmountGreaterThanBalance: false,
            isCustomPaymentAmountLessThanOne: false,
        }
    }

    componentDidMount() {
        const defaultPM = this.props.bankAccounts.filter(PM => !!PM.isDefault)[0]
        this.setState({selectedBankAccount: defaultPM})
        if (this.props.showPaymentSuccess) {
            this.showToastSuccess()
        }
        if (this.props.isNotAvailableToPay) {
            this.refresh()
        }
    }

    handlePaymentMethodChange = (e, {value}) => {
        const selectedBankAccount = this.props.bankAccounts.filter(bank => bank.stripeId === value)[0];
        this.setState({selectedBankAccount: selectedBankAccount})
    }

    handleChange = (e, {value}) => this.setState({
        amountOptionSelected: value,
        customPaymentAmount: '',
        isCustomPaymentAmountLessThanOne: false,
    })

    handleCustomPaymentAmount = (value) => this.setState({
        customPaymentAmount: value,
        requiredAmount: false,
        isCustomPaymentAmountGreaterThanBalance: value > (parseFloat(this.props.accountBalance)*2),
        isCustomPaymentAmountLessThanOne: value !== "" && value < 1
    })

    handleSubmission = () => {
        const {amountOptionSelected, customPaymentAmount, isCustomPaymentAmountGreaterThanBalance, isCustomPaymentAmountLessThanOne} = this.state;
        const isAmountOptionSelectedOther = amountOptionSelected === "other";
        const isAmountEmpty = !(Number(customPaymentAmount) > 0)
        if (isAmountOptionSelectedOther) {
            if (isCustomPaymentAmountGreaterThanBalance || isCustomPaymentAmountLessThanOne) {
                return;
            }
            if (isAmountEmpty) {
                this.setState({ requiredAmount: true });
                return;
            }
        }
        this.setState({
            payBillConfirmationOpen: true,
            requiredAmount: false,
        })
    }

    processAttachments = async (files) => {
        if (!files?.length) return

        return Promise.all(files.map(async file => {
            const content = await readFileAsync(file)
            const contentData = content.split(',')[1]

            return {
                fileName: file.name,
                contentType: file.type,
                contentData
            }
        }))
    }

    handlePayBill = async () => {
        const {accountBalance, invoiceId, billingMethod, isSelfBill} = this.props;
        const {amountOptionSelected, customPaymentAmount, selectedBankAccount, files} = this.state;
        const amountToPay = amountOptionSelected === "fullAmount" ? accountBalance : customPaymentAmount;
        let payload = {amount: amountToPay, sourceId: selectedBankAccount.stripeId}
        if (invoiceId) {
            payload["invoiceId"] = invoiceId
        }
        try {
            clearTimeout(this.timer)
            this.setState({
                disableMakePaymentButton: true
            })
            this.setPaymentInProgress(true)
            this.props.dispatch(setIsNotAvailableToPay(true))
            payload.isSelfBillMethod = isSelfBill;
            if (isSelfBill) {
                const attachments = await this.processAttachments(files)
                payload.attachment = attachments[0];
            }
            await axios.post("/api/employer/v2/createBankAccountPayment", payload);
            this.refresh()
            this.closePaymentModal()
        } catch (e) {
            this.showToastError()
            this.handleErrorMessages(e);
        }
    }

    setPaymentInProgress = async (inProgress) => {
        const {afterRefresh} = this.props;
        if (this.state.paymentInProgress === true && inProgress === false) {
            if (afterRefresh) {
                await afterRefresh(true);
            } else {
                if(this.props.isLastPaymentFailed) {
                    this.setState({loading: false})
                    this.showToastError()
                }
            }
        }
        this.setState({paymentInProgress: inProgress})
    }

    resolveErrorBankAccountPaymentMessage(e) {
        const paymentError = `Authorization error: This bank account is not authorized for premium payments.
            To resolve the issue reach out to your financial institution, or try a different payment method.
            For additional technical support, contact us at ${STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}
            or ${STRATEGIC_PARTNER.CONTACT_SUPPORT.EMAIL}.`

        const msgPaymentsTemporarilyUnavailable = `Online payments are temporarily unavailable.
            Please contact your Account Manager for further details and alternative payment methods.`

        if(e.response?.data?.message?.includes('exceeded your weekly ACH volume limit')) {
            return msgPaymentsTemporarilyUnavailable
        }

        return paymentError
    }

    handleErrorMessages(e) {
        console.warn(e)
        clearTimeout(this.timer)

        this.props.dispatch(setIsNotAvailableToPay(false))

        this.setState({
            loading: false,
            paymentInProgress: false,
            paymentError: this.resolveErrorBankAccountPaymentMessage(e),
            disableMakePaymentButton: false
        })
    }

    refresh = () => {
        this.timer = setTimeout(() => {
            this.refreshAfterPayment()
        }, 1300)
    }

    refreshAfterPayment = async () => {
        try {
            clearTimeout(this.timer)
            const result = await axios.post("/api/employer/v2/lastPaymentInfo", {headers: {'Content-Type': 'application/json'}});
            if(result.data.isNotAvailableToPay) {
                this.refresh()
            } else {
                const result = await axios.post("/api/employer/v2/prepareBilling", {headers: {'Content-Type': 'application/json'}});
                const data = result.data;
                this.props.dispatch(setBillingData(data))
                if (!data?.isNotAvailableToPay && !data?.isLastPaymentFailed){
                    this.showToastSuccess()
                }
                this.setPaymentInProgress(false)
            }
        } catch (e) {
            clearTimeout(this.timer)
            console.warn(e)
            this.setState({loading: false})
            this.showToastError()
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer)
    }

    closePaymentModal = () => {
        this.setState({
            paymentError: null,
            payBillConfirmationOpen: false,
            disableMakePaymentButton: this.props.isSelfBill,
            files: []
        })
    }

    buildBankAccountsDropdown = (bankAccounts) => {
        return bankAccounts.map(item => ({
            key: item.stripeId,
            text: item.brand + " (*********" + item.last4 + ")",
            value: item.stripeId
        }))
    }

    getTotalAmountToPay = () => {
        const {customPaymentAmount, amountOptionSelected} = this.state
        const {accountBalance} = this.props
        if (amountOptionSelected === 'fullAmount') {
            return strFormatMoney(accountBalance)
        }
        return strFormatMoney(customPaymentAmount)
    }

    showToastSuccess = () => {
        this.props.dispatch(showToast({
            type: 'success',
            title: "Payment submission success",
            message: "Your payment has been successfully submitted for processing. You’ll receive an email when your payment has processed or if we have any issues.",
        }));
    }

    showToastError = () => {
        this.props.dispatch(showToast({
            type: 'error',
            title:'There was a problem processing your payment.',
            message:'Please check your bank information, and try again later.'
        }));
    }

    render() {
        const {accountBalance, historicalInvoices, today, twoColumnsLayout, bankAccounts, isNotAvailableToPay, lastPaymentDate, lastPaymentAmount, paymentsUnappliedAmount} = this.props;
        const {
            customPaymentAmount,
            disableMakePaymentButton,
            payBillConfirmationOpen,
            paymentError,
            selectedBankAccount
        } = this.state;
        const bankAccountsDropdown = this.buildBankAccountsDropdown(bankAccounts);

        if (!bankAccountsDropdown || bankAccountsDropdown.length === 0) {
            return this.renderNotPaymentMethod();
        }

        const firstOrNext = !historicalInvoices || historicalInvoices.length === 0 ? "first" : "next";
        const formattedToday = moment(today, "DD/MM/YYYY").format("MMM DD, YYYY");
        return <>
            {payBillConfirmationOpen && this.authorizationPaymentModal(selectedBankAccount, formattedToday, paymentError, disableMakePaymentButton)}
            {twoColumnsLayout
                ? this.renderInTwoColumns(bankAccountsDropdown, selectedBankAccount, accountBalance, formattedToday, customPaymentAmount, historicalInvoices, firstOrNext, isNotAvailableToPay, lastPaymentDate, lastPaymentAmount, paymentsUnappliedAmount)
                : this.renderInOneColumn(bankAccountsDropdown, selectedBankAccount, accountBalance, formattedToday, customPaymentAmount, historicalInvoices, firstOrNext, isNotAvailableToPay)
            }
        </>
    }

    handleFilesUpdate(files) {
        if (files.length === 0) {
            this.setState({
                disableMakePaymentButton: true,
                files: []
            })
        } else {
            this.setState({
                disableMakePaymentButton: false,
                files: [files[0]]
            })
        }
    }

    authorizationPaymentModal(selectedBankAccount, formattedToday, paymentError, disableMakePaymentButton) {
        const {paymentInProgress, files} = this.state;
        const {isSelfBill} = this.props
        return <Modal
                size={"tiny"}
                open={this.state.payBillConfirmationOpen}
                onClose={this.closePaymentModal}
            >
            <CustomModalHeader title={"Make a payment"} onClose={this.closePaymentModal}/>
                <Modal.Content>
                    <Grid columns={2}>
                        {isSelfBill
                          ? <Grid.Column width={12}>
                              <p>Please upload your billing file for this period and confirm the details below to make your payment—</p>
                            </Grid.Column>
                          : <Grid.Column width={12}>
                              <p>Please confirm the details below to make your payment—</p>
                          </Grid.Column>
                        }
                        {isSelfBill &&
                          <Grid.Column width={12}>
                              <p className={"small neutral700Text"}><b>Self-bill invoice</b></p>
                              <UploadFile
                                multiple={false}
                                disabled={paymentInProgress}
                                files={files}
                                customText="File types accepted are CSV and XLSX"
                                hideAttachmentArea={files.length === 1}
                                validFileTypes={[
                                    'text/csv',
                                    // MS Office formats
                                    'application/msword',
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                    'application/vnd.ms-excel',
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                ]}
                                onFilesUpdate={files => this.handleFilesUpdate(files)}
                                style={{marginTop: 0}}
                              />
                          </Grid.Column>
                        }

                        <Grid.Column><p className={"small neutral700Text"}><b>Paying from</b></p></Grid.Column>
                        <Grid.Column textAlign={"right"}>{!!selectedBankAccount &&
                          <p>{selectedBankAccount.brand}(*******{selectedBankAccount.last4})</p>}</Grid.Column>
                        <Grid.Column><p className={"small neutral700Text"}><b>Date</b></p></Grid.Column>
                        <Grid.Column textAlign={"right"}><p>{formattedToday}</p></Grid.Column>
                        <Grid.Column><p className={"small neutral700Text"}><b>Amount</b></p></Grid.Column>
                        <Grid.Column textAlign={"right"}><Header
                          as="h3">{this.getTotalAmountToPay()}</Header></Grid.Column>
                    </Grid>
                    <CustomDivider hidden/>
                    {paymentError && <Message negative size="mini">{paymentError}</Message>}
                    <Button
                      loading={paymentInProgress}
                      disabled={disableMakePaymentButton}
                      primary
                      floated={"right"}
                      onClick={this.handlePayBill}>
                        Authorize payment
                    </Button>
                    <Button basic secondary floated={"right"} onClick={this.closePaymentModal}>Cancel</Button>
                </Modal.Content>
                <CustomDivider hidden/>
        </Modal>;
    }

    renderInTwoColumns(bankAccountsDropdown, selectedBankAccount, accountBalance, formattedToday, customPaymentAmount, historicalInvoices, firstOrNext, isNotAvailableToPay, lastPaymentDate, lastPaymentAmount, paymentsUnappliedAmount) {
        const isNegativeBalance = !!accountBalance && accountBalance < 0;
        return <Segment padded loading={isNotAvailableToPay}>
            <Grid columns={2} divided>
            <Grid.Column width={12} style={{paddingBottom: 0}}>
                <Header as="h4">Payment authorization</Header>
            </Grid.Column>
            <Grid.Row stretched>
                <Grid.Column>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                {this.renderBanksAccountsDropdown(bankAccountsDropdown, selectedBankAccount)}
                            </Grid.Column>
                        </Grid.Row>
                        {isNegativeBalance && this.renderNotAvailable("short")}
                        {!isNegativeBalance &&
                            <>
                                {this.renderPaymentForm(formattedToday, customPaymentAmount, accountBalance)}
                            </>
                        }
                    </Grid>

                    {accountBalance === "0.00" &&
                    <Segment style={{backgroundColor: "#FFF7EC", minHeight: 165, justifyContent: "center"}} textAlign={"center"}>
                        <Grid verticalAlign={"middle"}>
                            <CustomDivider hidden/>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <p style={{maxWidth: 310}} className={"neutral700Text small"}>
                                        Payment authorization isn't available until your {firstOrNext} invoice has been generated.
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                    }
                </Grid.Column>
                <Grid.Column>
                    {isNegativeBalance && this.renderNegativeBilling()}
                    {!isNegativeBalance && (
                        <CustomGrid columns={1} verticalAlign={"middle"}>
                            {this.renderMakeAPaymentButton()}
                        </CustomGrid>
                    )}
                    {isNotAvailableToPay && <>
                        <CustomDivider hidden />
                            <Segment style={{backgroundColor: '#FFFFFF', padding: '8px 10px', color: '#616161', border: '1px solid #E0E0E0', borderRadius: 8}}>
                                <Icon name={"info circle"} style={{float: 'left'}}/>
                                <div style={{float: "left"}}><span className={"smaller"}>Total amount may not reflect pending payments:<br/>Issued on {moment(lastPaymentDate).format('MMM D, YYYY')}. <b>Amount: {formatMoneyFixedTo2(lastPaymentAmount)}</b></span>
                                </div>
                            </Segment>
                        </>
                    }
                        {!isNotAvailableToPay && paymentsUnappliedAmount !== "0.00" &&
                        <>
                        <CustomDivider hidden />
                        <Segment style={{backgroundColor: '#FFFFFF', padding: '2px 10px'}}>
                            <Icon name={"info circle"} style={{float: 'left'}}/>
                            <div style={{float: "left"}}><span className={"smaller"}><i>Total amount does not reflect the following pending payments—</i><br/><b>Amount: {formatMoneyFixedTo2(paymentsUnappliedAmount)}</b></span></div>
                        </Segment>
                        </>
                    }
                </Grid.Column>
            </Grid.Row>
            </Grid>
        </Segment>;
    }

    renderInOneColumn(bankAccountsDropdown, selectedBankAccount, accountBalance, formattedToday, customPaymentAmount, historicalInvoices, firstOrNext, isNotAvailableToPay) {
        const isNegativeBalance = accountBalance < 0;
        const isAccountDifferentZero = accountBalance !== "0.00"
        return <Segment padded loading={isNotAvailableToPay} style={{minHeight: 397, maxHeight:397, display: 'flex', flexDirection: 'column', marginBottom: 0}}>
            <Header as="h4">Payment authorization</Header>
            <Grid columns={1} style={{flex: 1}}>
                <Grid.Row>
                    <Grid.Column>
                        {this.renderBanksAccountsDropdown(bankAccountsDropdown, selectedBankAccount)}
                    </Grid.Column>
                </Grid.Row>
                {isNegativeBalance && this.renderNotAvailable("tall")}

                {(!isNegativeBalance && isAccountDifferentZero) &&
                    <>
                        {this.renderPaymentForm(formattedToday, customPaymentAmount, accountBalance)}
                        {this.renderMakeAPaymentButton()}
                    </>
                  }
              </Grid>

            {accountBalance === "0.00" &&
            <Segment className={"bkgLinen"} style={{display: 'flex', alignItems: 'center',
                flex: 100, justifyContent: "center"}}
                     textAlign={"center"}>
                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <p className={"neutral700Text small"}>
                                Payment authorization isn’t available until your {firstOrNext} invoice has been generated.
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </Segment>
            }
        </Segment>;
    }

    renderMakeAPaymentButton() {
        return <Grid.Column>
            <Button data-qa-button_make_payment primary fluid
                    disabled={!this.state.selectedBankAccount}
                    onClick={this.handleSubmission}>Make a payment</Button>
        </Grid.Column>;
    }

    renderWarningText (warningText) {
        return <span className={"smaller warningRedText warningText"}>{warningText}</span>
    }

    renderPaymentForm(formattedToday, customPaymentAmount, accountBalance) {
        const {amountOptionSelected, requiredAmount, isCustomPaymentAmountGreaterThanBalance, isCustomPaymentAmountLessThanOne} = this.state
        const isAmountOptionSelectedOther = amountOptionSelected === "other";
        const isAmountOptionSelectedFullAmount = amountOptionSelected === "fullAmount";
        return <>
            <Grid.Row columns={2}>
                <Grid.Column width={8}>
                    <Form>
                        <Form.Field>
                            <label>Amount</label>
                            <Radio
                                label={<label>Total amount due: <b>{strFormatMoney(accountBalance)}</b></label>}
                                name='fullAmount'
                                value='fullAmount'
                                checked={isAmountOptionSelectedFullAmount}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                    </Form>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Form>
                        <Form.Field>
                            <label>Date</label>
                            <span className={"small"}>{formattedToday}</span>
                        </Form.Field>
                    </Form>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3} style={{paddingTop: 0}}>
                <Grid.Column width={2} style={{minHeight: '30px'}}>
                    <Form>
                        <Form.Field>
                            <Radio
                              data-qa-button_other
                              label='Other'
                              name='other'
                              value='other'
                              checked={isAmountOptionSelectedOther}
                              onChange={this.handleChange}

                            />
                        </Form.Field>

                    </Form>
                </Grid.Column>
                <Grid.Column width={4}>
                    {isAmountOptionSelectedOther &&
                      <Form style={{marginTop: '-5px'}}>
                          <Form.Field
                            error={isAmountOptionSelectedOther && (isCustomPaymentAmountGreaterThanBalance || requiredAmount || isCustomPaymentAmountLessThanOne)}>
                              <NumberFormat thousandSeparator
                                            prefix="$"
                                            customInput={Input}
                                            name={'customPaymentAmount'}
                                            onValueChange={(values, _) => {
                                                const {value} = values;
                                                this.handleCustomPaymentAmount(value)
                                            }}
                                            value={customPaymentAmount}
                                            decimalScale={2}
                                            fixedDecimalScale

                              />
                              {requiredAmount && (
                                <p className="textError">
                                    Required amount
                                </p>
                              )}
                          </Form.Field>
                      </Form>
                    }
                </Grid.Column>

                <Grid.Column width={6}>
                    {isAmountOptionSelectedOther && isCustomPaymentAmountGreaterThanBalance && this.renderWarningText("Payment amount exceeds limit")}
                    {isAmountOptionSelectedOther && isCustomPaymentAmountLessThanOne && this.renderWarningText("Amount cannot be less than one")}
                </Grid.Column>
            </Grid.Row>
        </>;
    }

    renderBanksAccountsDropdown(bankAccountsDropdown, selectedBankAccount) {
        return <Form>
            <Form.Field>
                <label>Paying from</label>
                {bankAccountsDropdown &&
                <Dropdown
                    fluid
                    selection
                    options={bankAccountsDropdown}
                    value={selectedBankAccount ? selectedBankAccount.stripeId : null}
                    onChange={this.handlePaymentMethodChange}
                />
                }
            </Form.Field>
        </Form>;
    }

    renderNotPaymentMethod() {
        const {isNotAvailableToPay} = this.props;
        return <Segment padded loading={isNotAvailableToPay} style={{minHeight: 397, maxHeight:397, display: 'flex', flexDirection: 'column'}}>
            <Header as="h4">Payment authorization</Header>
            <Segment className={"bkgLinen"} style={{flex: 1, display: 'flex', alignItems: 'center'}}>
                <Grid columns={1} textAlign={"center"} style={{flex: 1}}>
                    <Grid.Column textAlign={"center"}>
                        <Icon circular name='university' className={"neutral600Text"}
                              style={{fontSize: 30, backgroundColor: "#F7D2CC", boxShadow: "none"}}/>
                    </Grid.Column>
                    <Grid.Column textAlign={"center"}>
                        <p style={{textAlign: "center"}}><span className={"neutral700Text small"}><b>You have no e-payment option configured.<br/>Please set up an e-payment method in</b><br/><ClickableText
                            onClick={this.props.switchToPaymentDetail} small>payment details.</ClickableText> </span>
                        </p>
                    </Grid.Column>
                </Grid>
            </Segment>
        </Segment>
    }

    renderNotAvailable(containerSize) {
        return (
            <CustomGrid>
                <CustomGrid.Column>
                    <Segment className={"bkgLinen renderNotAvailableContainer " + containerSize}
                             textAlign={"center"}>
                        <CustomGrid>
                            <CustomGrid.Row columns={1}>
                                <CustomGrid.Column>
                                    <p className={"neutral700Text small"}>
                                        Payment authorization isn’t available until your next invoice has been generated.
                                    </p>
                                </CustomGrid.Column>
                            </CustomGrid.Row>
                        </CustomGrid>
                    </Segment>
                </CustomGrid.Column>
            </CustomGrid>
        );
    }

    renderNegativeBilling() {
        const {lastPaymentDate, accountBalance} = this.props;
        const lastPaymentDateFormat =  moment(lastPaymentDate).format("MM/DD/YYYY")
        return (
            <RenderNegativeBilling
                lastPaymentDate={lastPaymentDateFormat}
                accountBalance={accountBalance}
            />
        )
    }

}

export default connect(mapStateToProps)(withRouter(PaymentAuthorizationCard));
