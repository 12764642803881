import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Button, Grid, Header} from "semantic-ui-react";
import {connect} from "react-redux";
import Concierge from "../../../concierge";
import PortalModalHeader from "../../portalModalHeader";
import PortalFooter from "../../portalFooter";
import {setPrevRoute} from "../../../../actions/portalModalHeaderActions";
import {S3Image} from '../../../custom-common';

const mapStateToProps = (state, ownProps) => {
    const {employeeTermination} = state;
    return {
        employeeTermination,
        ...ownProps,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setPrevRoute: portalModalHeader => dispatch(setPrevRoute(portalModalHeader)),
    }
}

class EmploymentTerminationSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {conciergeOpen: false,};
    }

    async componentDidMount() {
        const {match, setPrevRoute} = this.props;
        const id = match.params.id;
        setPrevRoute({prevRoute: `/portal/employee/${id}`});
    }

    manageConciergeModal = (value) => {
        this.setState({conciergeOpen: value})
    }

    onClick = () => {
        const {match, history} = this.props;
        const id = match.params.id;
        history.push({pathname: `/portal/employee/${id}`});
    }

    render() {
        const {conciergeOpen} = this.state;

        return (
            <>
                <PortalModalHeader modalTitle={'Employment termination'} showRightMenu/>
                <div className="employer-portal-container" style={{background: 'white', paddingTop: '290px'}}>
                    <Grid container stackable columns={1}>
                        <Grid.Row>
                            <Grid.Column textAlign={'center'}>
                                <S3Image centered width={70} name="check"/>
                                <Header as='h2'>Success!</Header>
                                <p style={{marginBottom: '32px'}}>Your changes have been submitted.</p>
                                <Button onClick={this.onClick} primary style={{width: '160px', height: '45px'}}>
                                    Return to profile
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
                <PortalFooter/>
                <Concierge open={conciergeOpen} onManage={this.manageConciergeModal} hasDedicatedConcierge/>
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EmploymentTerminationSuccess));
