import React, {Component} from 'react'
import axios from 'axios'
import {Accordion, Dimmer, Grid, Icon, Loader, Message, Header} from 'semantic-ui-react'
import {downloadBlob, resolveErrorMessage} from '../../../../utils/utils'
import ProgressBar from './progressBar'
import Groups from './groups'
import Members from './members'
import Doughnut from './doughnut'
import './styles.css'
import {FAMILY_TIER_PROPERTIES, PARTICIPATION_RESULT_PROPERTIES, PROGRESS_STATUS_PROPERTIES} from "./constants";
import Concierge from "../../../concierge";
import STRATEGIC_PARTNER from "../../../../constants/strategicPartners";
import {ClickableText, CustomContainer} from '../../../custom-common';

class Analytics extends Component {
    state = {
        fetching: true,
        downloading: false,
        states: {
            quickView: true,
            summary: true,
        },
        active: null,
        members: [],
        analitycs: {},
        error: null,
        showConciergeModal: false,
    }

    componentDidMount() {
        this.fetchData()
    }

    showConciergeModal = (value) => {
        this.setState({ showConciergeModal: value })
    }

    handleSendMessageClick = e => {
        e.preventDefault()
        this.showConciergeModal(true)
    }

    parseMembers = (members) =>
        (!members || !members.length) ? []
            : members.map((m, key) => ({
                ...m,
                key: `${m.email}-${key}`,
            }));

    fetchData = async () => {
        const {
            enrollmentEventId,
            renewedContractId,
            activeContractId,
        } = this.props

        if (!enrollmentEventId) return

        this.setState({ fetching: true, error: null })
        try {
            const payload = {
                enrollmentEventId,
                renewedContractId,
                activeContractId,
            }
            const {data: {employeesEnrollments, analitycsData}} = await axios.post('/api/employer/v2/getEnrollmentsData', payload)
            this.setState({
                fetching: false,
                members: this.parseMembers(employeesEnrollments),
                analitycs: analitycsData,
            })
        } catch (e) {
            this.setState({
                fetching: false,
                error: resolveErrorMessage(e, 'Please try again later or contact support.'),
            })
        }
    }

    handleToggle = (_, { index }) => {
        this.setState(({ states }) => ({ states: { ...states, [index]: !states[index] } }))
    }

    handleDownload = async e => {
        e.preventDefault()
        e.stopPropagation()

        const { downloading } = this.state;
        const {
            enrollmentEventId,
            activeContractId,
        } = this.props;

        if (downloading || !enrollmentEventId) return

        this.setState({ downloading: true })
        try {
            const payload = { enrollmentEventId, activeContractId };
            const { data, headers }  = await axios.post('/api/employer/v2/getEnrollmentSummaryReport', payload,
                { responseType: 'blob' })

            const fileName = headers["content-disposition"]
                .split('filename=')[1]
                .replace(new RegExp("\"", 'g'), "");
            const contentType = headers["content-type"];

            downloadBlob(data, fileName, contentType)
        } catch (e) {
            console.log(e);
        }
        this.setState({ downloading: false })
    }

    handleGroupClick = ({ key: active }) => {
        this.setState(({ active: previous }) => ({ active: active !== previous ? active : null }))
    }

    get groups() {
        const { active, analitycs } = this.state

        const progressStatus = [];
        analitycs.progress.forEach(({name, counter}) => {
            const properties = PROGRESS_STATUS_PROPERTIES[name];
            progressStatus.push({
                ...properties,
                key: name,
                label: name,
                active: active === name,
                value: counter || 0,
            });
        });
        return progressStatus.sort((a, b) => a.order > b.order ? 1 : -1);
    }

    get members() {
        const { members, active } = this.state;

        return members.filter(({ status }) => status === active);
    }

    get participationData() {
        const { analitycs: {summary} } = this.state;

        const participationResults = [];
        summary.participationResults.forEach(({name, counter}) => {
            const properties = PARTICIPATION_RESULT_PROPERTIES[name];
            if (properties) {
                participationResults.push({
                    ...properties,
                    name,
                    y: counter,
                });
            }
        });
        return participationResults.sort((a, b) => a.order > b.order ? 1 : -1);
    }

    get tierDistributionData() {
        const { analitycs: {summary} } = this.state;
        const tierDistributions = [];
        summary.tierDistribution.forEach(({name, counter}) => {
            const properties = FAMILY_TIER_PROPERTIES[name];
            tierDistributions.push({
                ...properties,
                name,
                y: counter,
            });
        });
        return tierDistributions.sort((a, b) => a.order > b.order ? 1 : -1);
    }

    render() {
        const { states, fetching, active, members, error, downloading, showConciergeModal } = this.state;
        const { hasToDisplayReachOutMessage } = this.props;

        if (fetching && !members.length) return (
            <CustomContainer className='jb-analytics' style={{ height: '200px' }}>
                <Dimmer active inverted>
                    <Loader inverted content='Loading enrollment status...' />
                </Dimmer>
            </CustomContainer>
        )

        if (error) return (
            <Message negative size="mini">
                <Message.Header>Unable to get enrollment status</Message.Header>
                <p>{error}</p>
            </Message>
        )

        return (
            <CustomContainer className='jb-analytics'>
                <Header as='h3'>Enrollment analytics</Header>
                <Accordion className='jb-analytics__accordion'>
                    <Accordion.Title
                        active={states.quickView}
                        index='quickView'
                        onClick={this.handleToggle}
                    >
                        <Icon name='dropdown' />
                        Quick view
                    </Accordion.Title>
                    <Accordion.Content active={states.quickView} style={{ padding: '24px 8px' }}>
                        <Header as={"h5"}>Enrollment progress</Header>
                        <ProgressBar bars={this.groups} />
                        <Groups groups={this.groups} onClick={this.handleGroupClick} />
                        {active && (
                            <Members members={this.members} />
                        )}
                    </Accordion.Content>
                </Accordion>
                {!!members.length && (
                    <Accordion className='jb-analytics__accordion'>
                        <Accordion.Title
                            active={states.summary}
                            index='summary'
                            onClick={this.handleToggle}
                        >
                            <Icon name='dropdown' />
                            <a href='#download' className='jb-analytics__action' onClick={this.handleDownload}
                                style={downloading ? { opacity: 0.5, cursor: 'wait' } : {}}
                            >Download enrollment summary</a>
                            Summary
                        </Accordion.Title>
                        <Accordion.Content active={states.summary}>
                            <CustomContainer basic>
                                { hasToDisplayReachOutMessage &&
                                    <p style={{marginBottom: 25}}>A member of our team will be reaching out to provide
                                        enrollment summary and change reports if they haven't already. Please <ClickableText
                                            onClick={this.handleSendMessageClick}>send us a message</ClickableText> or call {STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER} if you have questions.</p>
                                }
                                <Grid divided stackable>
                                    <Grid.Row columns={2}>
                                        {!!this.participationData.length && (
                                            <Grid.Column style={{ paddingRight: '52px' }}>
                                                <Header as='h5'>Participation results</Header>
                                                <Doughnut
                                                    size={125}
                                                    data={this.participationData}
                                                />
                                            </Grid.Column>
                                        )}
                                        {!!this.tierDistributionData.length && (
                                            <Grid.Column>
                                                <Header as='h5'>Tier distribution</Header>
                                                <Doughnut
                                                    size={125}
                                                    data={this.tierDistributionData}
                                                />
                                            </Grid.Column>
                                        )}
                                    </Grid.Row>
                                </Grid>
                            </CustomContainer>
                        </Accordion.Content>
                    </Accordion>
                )}
                <Concierge open={showConciergeModal} onManage={this.showConciergeModal}/>
            </CustomContainer>
        )
    }
}

export default Analytics
