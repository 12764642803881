import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {resolveStepUrl} from '../../../utils/common'
import {setLogin, logout} from '../../../actions/authActions'
import StaticFooter from '../../staticFooter'
import STRATEGIC_PARTNER from '../../../constants/strategicPartners'
import {CustomDivider, S3Image} from "../../custom-common";
import IMAGE from "../../../constants/images";
import {Header} from "semantic-ui-react";
import LoginForm from "./loginForm";


const Login = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(logout())

    const urlSearchParams = new URLSearchParams(window.location.search);
    const authToken = urlSearchParams.get('t');

    if (!authToken) return

    const contractSfId = urlSearchParams.get('cSfId');
    const step = urlSearchParams.get('s');
    const username = urlSearchParams.get('n');

    setLoading(true)

    dispatch(setLogin({ authToken, contractSfId, step, username }));
    history.push({pathname: resolveStepUrl(step)});
  }, [dispatch, history])

  return (
         <div className={`loginLayout ${loading ? 'loginLoading' : ''}`}>
              <div className={'loginInfoPanelLayout'}>
                  <div className={'loginBrand'}>
                      <S3Image {...IMAGE.OFFICIAL_LOGO_WHITE_258PX}/>
                      <S3Image {...IMAGE.OFFICIAL_LOGO_258PX}/>
                  </div>
                  <div className={'loginInfoLayout'}>
                    <Header as='h1' className={'loginMainHeader'}>
                          Everything you need to administer {STRATEGIC_PARTNER.LABEL}.
                    </Header>
                    <div className={'loginWelcome'}>Right this way—</div>
                  </div>
              </div>
              <div className={'loginContentPanel'}>
                  <div className={'loginContent'}>
                      <Header as={'h3'}>Employer Portal Login</Header>
                      <p className={'small loginSubtitle'}>Enter your details to access your {STRATEGIC_PARTNER.LABEL} portal—</p>
                      <CustomDivider hidden/>
                      <LoginForm/>
                  </div>
                  <div className={'loginFooter'}>
                      <StaticFooter />
                  </div>
              </div>
         </div>
        )
  }

export default Login
