import moment from "moment";
import PropTypes from "prop-types";
import {Icon, Segment} from "semantic-ui-react";
import {CustomGrid, S3Image} from "../../custom-common";
import {strFormatMoney} from "../../../utils/utils";
import IMAGE from "../../../constants/images";

const RenderNegativeBilling = ({lastPaymentDate, accountBalance}) => {
    const lastPaymentDateFormat =  moment(lastPaymentDate).format("MM/DD/YYYY")
    const strAccountBalance = accountBalance.replace(/-/g, '');
    return (
        <CustomGrid textAlign={"center"} className="renderNegativeBillingGrid">
            <CustomGrid.Row fitted={"vertically"}>
                <S3Image {...IMAGE.CHECK_80PX} />
            </CustomGrid.Row>
            <CustomGrid.Row fitted={"vertically"}>
                <p className="centerAligned">
                        <span className={"small"}>
                            <b>Your account is all paid up!</b>
                        </span>
                    <br/>
                    <span className={"smaller neutral700Text"}>
                            Last payment date {lastPaymentDateFormat}
                        </span>
                </p>
            </CustomGrid.Row>

            <Segment className="creditContainer">
                <Icon name={"info circle"} color="grey" className="floatLeft"/>
                <div className="floatLeft">
                      <span className={"smaller msgAccount"}>
                          Your account has a <b>{strFormatMoney(strAccountBalance)} credit</b>
                      </span> <br/>
                    <span className={"smaller "}>
                        This amount will be automatically applied to your next invoice.
                    </span>
                </div>
            </Segment>
        </CustomGrid>
    )
}

RenderNegativeBilling.propTypes = {
    lastPaymentDate: PropTypes.string.isRequired,
    accountBalance: PropTypes.string.isRequired,
};

export default RenderNegativeBilling;