import React, {Component} from 'react'
import axios from 'axios'
import {Dimmer, Grid, Loader, Message, Header} from 'semantic-ui-react'
import {resolveErrorMessage} from '../../utils/utils'
import PrePortalHeader from '../prePortalHeader'
import {PlaidButton} from './plaidButton'
import {Account} from './account'
import STRATEGIC_PARTNER from '../../constants/strategicPartners'
import {CustomDivider} from '../custom-common'
import {show as showToast} from "../../actions/toastActions";
import {connect} from 'react-redux';

const mapDispatchToProps = dispatch => {
    return {
        showToast: (toastDetails) => dispatch(showToast(toastDetails))
    }
};

const parsePlaidEnv = (env) => {
    const match = (env && env.match(/^https:\/\/(\w*?)\.plaid\.com$/)) // eslint-disable-line no-useless-escape
    return (match && match[1]) || 'production'
}

export class PMLinkPage extends Component {
    state = {
        preparing: true,
        fetching: false,
        plaidInfo: null,
        employer: {name: '[[ employer name ]]'},
        error: null,
        accounts: [],
        employerId: null,
    }

    async componentDidMount() {
        let newState
        try {
            const payload = {token: this.token}
            const {
                data: {
                    plaidLinkToken: token,
                    plaidEnvironment,
                    displayName,
                    employerId
                }
            } = await axios.post('/api/employer/v2/prepareAddPaymentMethodWithLinkToken', payload)
            const env = parsePlaidEnv(plaidEnvironment)
            newState = {
                plaidInfo: {token, env},
                employer: {displayName},
                employerId,
            }
        } catch (e) {
            console.error('Prepare app error:', e?.response?.data || e.message)
            newState = {error: 'The token is expired or invalid.'}
        } finally {
            this.setState({
                ...newState,
                preparing: false
            })
        }
    }

    onPlaidSuccess = async (_, {public_token: publicToken, account_id: accountId}) => {
        this.setState({fetching: true})
        try {
            const payload = {
                token: this.token,
                publicToken,
                accountId,
                accountSfId: this.state.employerId,
            }
            const {
                data: {
                    id,
                    bankName,
                    last4
                }
            } = await axios.post('/api/employer/v2/addPaymentMethodWithLinkToken', payload)
            this.setState(({accounts}) => ({
                accounts: [...accounts, {id, bankName, last4}],
                fetching: false
            }))
            this.showToastSuccess({
                message: 'Payment method added successfully'
            })
        } catch (e) {
            const error = resolveErrorMessage(e)
            console.error('Add payment error:', error)
            const details = /is already registered/.test(error)
                ? 'This payment method already exists in the account.'
                : 'Please check your bank information, and try again.'
            this.setState({
                fetching: false
            })
            this.showToastError({
                title: 'Error adding payment method',
                message: details
            })
        }
    }

    handleRemoveAccount = async (id) => {
        this.setState({fetching: true})
        try {
            const payload = {token: this.token, paymentMethodId: id, accountSfId: this.state.employerId}
            await axios.post('/api/employer/v2/removePaymentMethodWithLinkToken', payload)
            this.setState(({accounts}) => ({
                accounts: accounts.filter(a => a.id !== id),
                fetching: false
            }))
            this.showToastSuccess({
                title: 'Payment method removed successfully'
            })
        } catch (e) {
            console.error('Remove payment error:', resolveErrorMessage(e))
            this.setState({
                fetching: false
            })
            this.showToastError({
                title: 'Error removing payment method',
                message: 'Please, try again or contact the support team.'
            })
        }
    }

    renderError = () => {
        const {error} = this.state

        return (
            <Grid.Column style={{marginTop: '6em'}} width={10}>
                <Message negative>
                    <Message.Header>Invalid link</Message.Header>
                    {error && (<pre style={{whiteSpace: 'pre-wrap'}}>{error}</pre>)}
                </Message>
            </Grid.Column>
        )
    }

    get token() {
        const parsedUrl = new URL(window.location.href)

        return parsedUrl.searchParams.get('token')
    }

    renderSubtitle = () => {
        const {employer = {}} = this.state

        if (!employer.displayName) return `Set up a new payment method for ${STRATEGIC_PARTNER.LABEL} invoices—`

        const displayName = employer.displayName.trim()
        const lastChar = displayName.substr(-1).toLowerCase()

        const noun = lastChar === 's' ? `${displayName}'` : `${displayName}'s`

        return `Set up a new payment method for ${noun} ${STRATEGIC_PARTNER.LABEL} invoices—`
    }

    showToastSuccess = (details) => {
        this.props.showToast({type: 'success', ...details})
    }

    showToastError = (details) => {
        this.props.showToast({type: 'error', ...details})
    }

    render() {
        const {preparing, fetching, error, accounts, plaidInfo} = this.state

        if (preparing) return (
            <Dimmer page active>
                <Loader size='medium'>Preparing...</Loader>
            </Dimmer>
        )

        const {token, env} = plaidInfo || {}


        return (<>
            <PrePortalHeader/>
            <div className="employer-portal-container">
                <Grid container stackable columns={1} centered style={{marginTop: 0}}>
                    <Grid.Column className={"pageContent"}>
                        <Grid centered>
                            {error ? this.renderError() : (
                                <Grid.Column style={{marginTop: '4em'}} width={8}>
                                    <Header as={"h2"}>Add payment method</Header>
                                    <p>{this.renderSubtitle()}</p>
                                    <p>
                                        By adding an electronic payment method you authorize {STRATEGIC_PARTNER.LEGAL.SERVICE_NAME} on behalf of {STRATEGIC_PARTNER.LABEL} to
                                        save the bank or financial institution information to your company's profile to be used for future premium payments.
                                        This authorization can be cancelled at any time by deleting the payment method.
                                        All payment methods are visible to your employer users and can be used for premium payments.
                                    </p>
                                    <CustomDivider hidden/>
                                    {!!accounts.length && (<>
                                        {accounts.map(acc => <Account key={acc.id} {...acc}
                                                                      onRemove={this.handleRemoveAccount}/>)}
                                        <CustomDivider hidden/>
                                    </>)}
                                    <PlaidButton
                                        fetching={fetching}
                                        token={token}
                                        env={env}
                                        onSuccess={this.onPlaidSuccess}
                                    >+ Add payment method</PlaidButton>
                                </Grid.Column>
                            )}
                        </Grid>
                    </Grid.Column>
                </Grid>
            </div>
        </>)
    }
}

export default connect(null, mapDispatchToProps)(PMLinkPage)
