import React, {Component} from 'react';
import moment from "moment";
import {Link} from "react-router-dom";
import {formatMoney} from "../../../utils/utils";
import {Grid, Icon, Segment, Header} from "semantic-ui-react";

class PreviewInvoice extends Component {
    render() {
        const {previewInvoice, isSelfBill} = this.props;
        const startDate = moment(previewInvoice.billingPeriod.startDate, 'YYYY/MM/DD').format('MM/DD/YYYY')
        const endDate = moment(previewInvoice.billingPeriod.endDate, 'YYYY/MM/DD').format('MM/DD/YYYY')

        return <Segment padded style={{minHeight: 100, width: '100%'}}>
            <Grid columns={3}>
                <Grid.Column width={12} style={{paddingBottom: 0}} className={"small"}>
                    <i>Your upcoming invoice will be finalized at the end of your current billing period, preview it below—</i>
                </Grid.Column>
                {!isSelfBill &&
                  <Grid.Column width={1}>
                      <Icon circular name='eye' className="eyePreviewIcon"/>
                  </Grid.Column>
                }
                <Grid.Column width={11} className="pl-0">
                    <Grid>
                        <Grid.Row verticalAlign={"middle"} style={{paddingBottom: 0}}>
                            <Grid.Column width={9}>
                                <Header as={"h4"} style={{display: 'inline', marginRight: '20px'}}>Preview</Header>
                                <span
                                    className={"small neutral700Text"}><i>Estimated upcoming billing for <b>{startDate} - {endDate}</b></i></span>
                            </Grid.Column>
                            <Grid.Column width={3} textAlign={"right"}>
                                <Link className="linkText small"
                                      to={{pathname: "/portal/invoice/" + previewInvoice.id, state: {type: 'preview'}}}>Preview bill</Link>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row verticalAlign={"middle"} style={{paddingTop: 0}}>
                        <Grid.Column width={12}>
                                                    <span
                                                        className={"small neutral700Text"}><b>Estimated amount</b></span>
                                <Header as={"h4"} style={{display: 'inline', marginLeft: '15px'}}>{formatMoney(previewInvoice.totalAmount)}</Header>
                        </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>


            </Grid>
        </Segment>
    }
}

export default PreviewInvoice;
