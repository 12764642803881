import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Loader, Message, Header} from "semantic-ui-react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import BankAccount from "./BankAccount";
import Plaid from "./plaid";
import axios from "axios";
import {resolveErrorMessage} from "../../../utils/utils";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import MicroDepositsBankAccount from "./microDepositsBankAccount";
import {deleteBankAccount, removePendingVerificationBankAccount, setBankAccount, setDefaultBankAccount, setPendingVerificationBankAccount, setPendingVerificationBankAccountFailed,} from "../../../actions/bankAccountActions";
import STRATEGIC_PARTNER from '../../../constants/strategicPartners';
import {CustomDivider} from '../../custom-common';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        bankAccounts: state.bankAccount.bankAccounts,
        defaultBankAccount: state.bankAccount.defaultBankAccount,
        pendingVerificationBankAccounts: state.bankAccount.pendingVerificationBankAccounts,
        creationVisibility: state.bankAccount.creationVisibility,
        editionVisibility: state.bankAccount.editionVisibility,
        selectedBankAccount: state.bankAccount.selectedBankAccount,
        plaidLinkToken: state.billing.plaidLinkToken,
        plaidEnvironment: state.billing.plaidEnvironment,
        accountSfId: state.billing.employerId,
    }
};

class PaymentDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            savingPM: false,
            error: null,
        }
    }

    prepareBankAccountPayload = ({public_token, account_id, metadata, status}) => {
        const {accountSfId} =  this.props
        return {
            status,
            metadata,
            accountSfId,
            publicToken: public_token,
            accountId: account_id,
        }
    }

    addBankAccount = async (data) => {
        if (data.account?.verification_status === "pending_manual_verification") {
            await this.addMicroDepositsBankAccount(this.prepareBankAccountPayload(data));
        } else {
            await this.addBankAccountByAuth(this.prepareBankAccountPayload(data));
        }
    }

    async addMicroDepositsBankAccount(payload) {
        try {
            this.setState({savingPM: true, error: null})
            const result = await axios.post("/api/employer/v2/addMicroDepositsBankAccount", payload, {headers: {'Content-Type': 'application/json'}});
            this.props.dispatch(setPendingVerificationBankAccount(result.data.microDepositsBankAccount));
            this.setState({savingPM: false, error: null})
        } catch (err) {
            this.setState({savingPM: false, error: resolveErrorMessage(err, "An unexpected error ocurred.")})
        }
    }

    async addBankAccountByAuth(payload) {
        try {
            this.setState({savingPM: true, error: null})
            const response = await axios.post("/api/employer/v2/addBankAccount", payload, {headers: {'Content-Type': 'application/json'}});
            const {metadata} = payload
            this.props.dispatch(setBankAccount({...response.data, metadata}))
            this.setState({savingPM: false, error: null})
        } catch (err) {
            this.setState({savingPM: false, error: resolveErrorMessage(err, "An unexpected error ocurred.")})
        }
    }

    setDefaultBankAccount = async (id) => {
        try {
            const {accountSfId} =  this.props
            this.setState({loading: true, error: null})
            const payload = {paymentMethodId: id, accountSfId}
            await axios.post("/api/employer/v2/updateDefaultBankAccount", payload, {headers: {'Content-Type': 'application/json'}});
            this.props.dispatch(setDefaultBankAccount(id))
            this.setState({loading: false, error: null})
        } catch (e) {
            this.setState({loading: false, error: resolveErrorMessage(e, "An unexpected error ocurred.")})
        }
    }

    removeBankAccount = async (accountId) => {
        try {
            this.setState({loading: true, error: null})
            const {accountSfId} =  this.props
            const payload = {
                accountId,
                accountSfId,
                paymentMethodId: accountId
            }
            await axios.post("/api/employer/v2/removeBankAccount", payload, {headers: {'Content-Type': 'application/json'}});
            this.props.dispatch(deleteBankAccount(accountId))
            this.setState({loading: false, error: null})
        } catch (e) {
            this.setState({loading: false, error: resolveErrorMessage(e, "An unexpected error ocurred.")})
        }
    }

    hidePendingVerificationBankAccount = (accountId) => {
        const {dispatch} = this.props
        dispatch(removePendingVerificationBankAccount(accountId))
    }

    removePendingVerificationBankAccount = (accountId) => {
        try {
            this.setState({error: null})
            const payload = this.prepareBankAccountPayload({account_id: accountId})
            axios.post("/api/employer/v2/removeMicroDepositsBankAccount", payload, {headers: {'Content-Type': 'application/json'}});
            this.hidePendingVerificationBankAccount(accountId)
        } catch (e) {
            this.setState({error: resolveErrorMessage(e, "An unexpected error ocurred.")})
        }
    }

    updateMicroDepositsBankAccountStatus = ({account_id, status, metadata, public_token}) => {
        try {
            this.setState({loading: true})
            const payLoad = this.prepareBankAccountPayload({status, account_id, metadata, public_token})
            axios.post("/api/employer/v2/updateMicroDepositsBankAccountStatus", payLoad, {headers: {'Content-Type': 'application/json'}});
            this.setState({loading: false})
        }  catch (err) {
            this.setState({
                loading: false,
                error: resolveErrorMessage(err, "An unexpected error ocurred.")
            })
        }
    }

    tooManyVerificationAttemptsCallback = (accountId) => {
        try {
            this.setState({error: null})
            this.props.dispatch(setPendingVerificationBankAccountFailed(accountId));
        } catch (e) {
            this.setState({error: resolveErrorMessage(e, "An unexpected error ocurred.")})
        }
    }

    render() {
        const {bankAccounts, plaidEnvironment, plaidLinkToken, pendingVerificationBankAccounts} = this.props;
        const {savingPM, error, loading} = this.state;
        return (
            <Segment style={{padding: 50}}>
                <Grid columns='equal'>
                    <Grid.Row verticalAlign={"middle"}>
                        <Grid.Column textAlign={"left"}>
                            <Header as="h4">Payment settings</Header>
                        </Grid.Column>
                        <Grid.Column textAlign={"right"}>
                            {!!plaidLinkToken && <Plaid plaidEnv={plaidEnvironment}
                                                        plaidLinkToken={plaidLinkToken}
                                                        setPlaidData={this.addBankAccount}
                                                        loading={savingPM || loading}
                                                        setLoader={this.setLoader}
                            />}
                        </Grid.Column>
                    </Grid.Row>
                    {error &&
                        <Grid.Column width={12}><Message error>{error}</Message></Grid.Column>
                    }
                    <Grid.Row verticalAlign={"middle"}>
                        <Grid.Column>
                            By adding an electronic payment method you authorize {STRATEGIC_PARTNER.LEGAL.SERVICE_NAME} on behalf of {STRATEGIC_PARTNER.LABEL} to
                            save the bank or financial institution information to your company's profile to be used for future premium payments.
                            This authorization can be cancelled at any time by deleting the payment method.
                            All payment methods are visible to your employer users and can be used for premium payments.
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Column width={12}>
                        {savingPM || loading ? <Loader active/> :
                            ((bankAccounts && bankAccounts.length > 0) || (pendingVerificationBankAccounts && pendingVerificationBankAccounts.length > 0)) ?
                                <>
                                    {(bankAccounts && bankAccounts.length > 0) && bankAccounts.map((bankAccount) => {
                                        return (
                                            <BankAccount key={bankAccount.stripeId} id={bankAccount.stripeId}
                                                         name={bankAccount.brand}
                                                         lastAccountNumbers={bankAccount.last4}
                                                         defaultBankAccount={bankAccount.isDefault}
                                                         setDefault={this.setDefaultBankAccount}
                                                         remove={this.removeBankAccount}
                                                         verified={true}
                                                         bankAccountsListLength={bankAccounts.length}
                                                         bankAccountData={bankAccount}

                                            />
                                        )
                                    })}
                                    {(pendingVerificationBankAccounts && pendingVerificationBankAccounts.length > 0) && pendingVerificationBankAccounts.map((mdBankAccount) =>
                                        (<MicroDepositsBankAccount key={mdBankAccount.accountId}
                                                                      id={mdBankAccount.accountId}
                                                                      name={mdBankAccount.name}
                                                                      lastAccountNumbers={mdBankAccount.mask}
                                                                      setDefault={this.setDefaultBankAccount}
                                                                      remove={this.removePendingVerificationBankAccount}
                                                                      hide={this.hidePendingVerificationBankAccount}
                                                                      tooManyVerificationAttemptsCallback={this.tooManyVerificationAttemptsCallback}
                                                                      updateStatus={this.updateMicroDepositsBankAccountStatus}
                                                                      plaidEnv={plaidEnvironment}
                                                                      plaidLinkToken={mdBankAccount.plaidLinkToken}
                                                                      setPlaidData={this.addBankAccount}
                                                                      loading={savingPM || loading}
                                                                      setLoader={this.setLoader}
                                                                      bankDescription={mdBankAccount.name}
                                                                      status={mdBankAccount.status}
                                        />))}
                                </>

                                : <Segment className={"bkgLinen"}>
                                    <Grid columns={1} textAlign={"center"}>
                                        <Grid.Column textAlign={"center"}>
                                            <CustomDivider hidden/>
                                            <Icon circular name='university' className={"neutral600Text"}
                                                  style={{
                                                      fontSize: 30,
                                                      backgroundColor: "#F7D2CC",
                                                      boxShadow: "none"
                                                  }}/>
                                        </Grid.Column>
                                        <Grid.Column textAlign={"center"}>
                                            <p style={{textAlign: "center"}}><span
                                                className={"neutral700Text small"}><b>You have no e-payment option configured.<br/>Please set up an e-payment method.</b></span>
                                            </p>
                                            <CustomDivider hidden/>
                                        </Grid.Column>
                                    </Grid>
                                </Segment>
                        }
                    </Grid.Column>


                </Grid>
            </Segment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(PaymentDetails));
