import React, {Component} from 'react';
import {Grid, Header} from "semantic-ui-react";
import {formatMoney} from "../../../utils/utils";
import {Link} from "react-router-dom";
import moment from "moment";
import {connect} from "react-redux";
import BorderedSegment from "../../borderedSegment";
import {CustomContainer} from "../../custom-common";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        historicalInvoices: state.billing.historicalInvoices,
    }
};

class InvoiceHistory extends Component {

    renderInvoice(invoice, index) {
        const borderColor = this.getInvoiceBorderColor(invoice.paymentStatus)
        return <Grid.Column width={16} key={index} style={{paddingTop: 0, paddingBottom: '0.5em'}}>
            <BorderedSegment borderColor={borderColor}>
                    <Grid columns={5}>
                        <Grid.Column>{this.parseInvoiceNumber(invoice.invoiceNumber)}</Grid.Column>
                        <Grid.Column>{this.parseCoveragePeriod(invoice.billingPeriod)}</Grid.Column>
                        <Grid.Column>{formatMoney(invoice.totalAmount)}</Grid.Column>
                        <Grid.Column>{this.renderPaymentStatus(invoice.paymentStatus)}</Grid.Column>
                        <Grid.Column><Link data-qa-button_view_bill className="linkText" to={{pathname: "/portal/invoice/" + invoice.id, state: {type: 'final'}}}>View bill</Link></Grid.Column>
                    </Grid>
                </BorderedSegment>
            </Grid.Column>
    }

    parseInvoiceNumber(value) {
        let invoiceNumber = value.split("-")[1];
        return invoiceNumber ? "#" + invoiceNumber : value;
    }

    parseCoveragePeriod(billingPeriod) {
        return moment().month(billingPeriod.month - 1).format("MMM") + " " + billingPeriod.year
    }

    getInvoiceBorderColor(status) {
        switch (status) {
            case "Due":
                return "#F0A446"
            case "Past due":
                return "#D82000"
            case "Paid in full":
                return "#43806C"
            case "Partially paid":
                return "#F0A446"

            default:
                return "";
        }
    }

    renderPaymentStatus(status) {
        switch (status) {
            case "Due":
                return <b className={"sunshade"}>Due</b>;
            case "Past due":
                return <b className={"warningRedText"}>Past due</b>;
            case "Paid in full":
                return <b className={"eucalyptusText"}>Paid in full</b>;
            case "Partially paid":
                return <b className={"sunshade"}>Partially paid</b>;

            default:
                return "-";
        }
    }

    render() {
        const {historicalInvoices} = this.props;
        return (
            <>
                <Header as="h4">Invoice history</Header>
                <CustomContainer basic>
                <Grid columns={5}>
                        <Grid.Column className={"neutral700Text"} style={{minWidth: "135px"}}>
                            <b>Invoice</b>
                        </Grid.Column>
                        <Grid.Column className={"neutral700Text"} style={{minWidth: "135px"}}>
                            <b>Coverage period</b>
                        </Grid.Column>
                        <Grid.Column className={"neutral700Text"} style={{minWidth: "135px"}}>
                            <b>Total amount</b>
                        </Grid.Column>
                        <Grid.Column className={"neutral700Text"} style={{minWidth: "135px"}}>
                            <b>Status</b>
                        </Grid.Column>
                        <Grid.Column className={"neutral700Text"} style={{minWidth: "135px"}}>
                            <b>Details</b>
                        </Grid.Column>
                </Grid>
                </CustomContainer>
                <Grid columns={5}>
                    {historicalInvoices && historicalInvoices.map((item, index) => (
                        this.renderInvoice(item, index)
                    ))}
                </Grid>
            </>
        )
    }
}

export default connect(mapStateToProps)(InvoiceHistory);
