import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {
    Button,
    Icon,
    Popup,
    Ref,
    Segment,
    Transition,
    List,
    Header
} from "semantic-ui-react";
import {connect} from "react-redux";
import {formatDate, formatMoney, formatMontlyCost} from "../../../utils/utils";
import RatedClassUtil from "../../../utils/ratedClassUtil";
import {contributionTypes} from "../../../utils/strings";
import FamiliyTierAgeBandContributionTable from "../../familiyTierAgeBandContributionTable";
import BorderedSegment from "../../borderedSegment";
import STRATEGIC_PARTNER from "../../../constants/strategicPartners";
import { MAX_EMPLOYEE_ELIGIBILITY_AGE } from "../../../constants/contract";
import { hasPolicyInformation } from '../../../selectors/contract'
import {StrategicPartnerLabel} from "../../common";
import BenefitLimits from "../../benefits";
import {CustomGrid, CustomDivider} from '../../custom-common';


const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        hasPolicyInformation: hasPolicyInformation(state),
    }
};

class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showDetails: false,
            expandedRatedClass: 0,
        }
    }

    toggleDetails = () => {
        const {showDetails} = this.state;
        this.setState({showDetails: !showDetails})
    }

    contractPolicyNumber = () => {
        const {data} = this.props
        if (!data?.contract) return ''
        const {carrierSpecificPolicyId, policyNumber} = data.contract
        return carrierSpecificPolicyId || policyNumber
    }

    render() {
        const {showDetails} = this.state;
        const {data, hasPolicyInformation} = this.props;

        if (!hasPolicyInformation) return (
            <Segment basic textAlign='center' padded='very'>
                <CustomDivider hidden section/>
                <Header as='h4' className='neutral700Text'>No Policy information</Header>
                <p className='neutral700Text'>
                    Unable to retrieve plan information,<br />
                    please reach out to us to learn more.
                </p>
                <CustomDivider hidden section/>
            </Segment>
        )

        return (
            <React.Fragment>
                <Header as="h4">Policy overview</Header>

                <CustomDivider hidden/>

                <CustomGrid columns={4}>
                    <CustomGrid.Column>
                        <p>
                            <span className={"small neutral700Text"}><b>Policy holder</b><br/></span>
                            {data.employer?.legalEntityName}
                        </p>
                    </CustomGrid.Column>
                    <CustomGrid.Column>
                        <p>
                            <span className={"small neutral700Text"}><b>Policy number</b><br/></span>
                            {this.contractPolicyNumber()}
                        </p>
                    </CustomGrid.Column>
                    <CustomGrid.Column>
                        <p>
                            <span className={"small neutral700Text"}><b>Policy effective date</b><br/></span>
                            {formatDate(data.contract?.policyEffectiveDate)}
                        </p>
                    </CustomGrid.Column>
                    <CustomGrid.Column>
                        <p>
                            <span className={"small neutral700Text"}><b>Anniversary date</b><br/></span>
                            {formatDate(data.contract?.policyAnniversaryDate)}
                        </p>
                    </CustomGrid.Column>
                    <CustomGrid.Column>
                        <p>
                            <span className={"small neutral700Text"}><b>Affiliates/subsidiaries</b><br/></span>
                            {data.employer?.affiliatesSubsidiaries}
                        </p>
                    </CustomGrid.Column>
                </CustomGrid>

                <CustomDivider hidden/>
                <p>
                  If you have questions about your policy or coverage details, please reach out to us—
                </p>
                <CustomDivider hidden/>
                <p>
                  This is a summary of the important features of your coverage. This is not the insurance contract.
                  Please refer to your policy documents for more information.
                </p>
                <CustomDivider hidden/>

                {showDetails && this.renderDetails()}

                <Button data-qa-button_details primary basic compact
                        onClick={this.toggleDetails}>{showDetails ? '- Hide details' : '+ Show details'}</Button>
                <CustomDivider hidden/>
            </React.Fragment>
        )
    }

    toggleExpandedRatedClass = (index) => {
        const {expandedRatedClass} = this.state
        if (index === expandedRatedClass) {
            index = -1
        }
        this.setState({expandedRatedClass: index})
    }

    scrollRefToTop = (ref) => {
        window.scrollTo({top: ref?.current?.offsetTop || 0, behavior: 'smooth'})
    }

    renderRatedClass = (ratedClass, index) => {
        const {ageRangesPerClass, data} = this.props;
        const {expandedRatedClass} = this.state;
        const ratedClassUtil = new RatedClassUtil(ratedClass);
        const showDetails = expandedRatedClass === index;
        const ageRanges = ageRangesPerClass[0].ageRanges;
        const ref = React.createRef();
        const toggleable = {className: "clickable", onClick: () => this.toggleExpandedRatedClass(index)};
        const percentagePayableDuringBenefitWaitingPeriod = ratedClass.contractTerms?.percentagePayableDuringBenefitWaitingPeriod ?? 0;
        const isBenefitWaitingPeriodPayable = percentagePayableDuringBenefitWaitingPeriod > 0;

        const getSpouseEligibilityMessage = () => {
            const isDomesticEligible = ratedClassUtil.isDomesticPartnerEligible();
            const isCivilEligible = ratedClassUtil.isCivilUnionEligible();
            if (isDomesticEligible && isCivilEligible) {
                return "Domestic partnerships and civil unions are eligible for coverage";
            } else if (isDomesticEligible) {
                return "Domestic partnerships are eligible for coverage";
            } else if (isCivilEligible) {
                return "Civil unions are eligible for coverage";
            }
            return "Domestic partnerships and civil unions are not eligible for coverage";
        };

        return (
            <React.Fragment>
                {!showDetails ? (
                    <Segment styled className={"clickable"} onClick={() => this.toggleExpandedRatedClass(index)}>
                        <b>{ratedClass.groupClass.documentDescription}</b>
                        <Icon style={{float: 'right'}} name={'chevron down'}/>
                    </Segment>
                ) : (
                    <Ref innerRef={ref}>
                        <Transition transitionOnMount animation="slide down" duration={100}
                                    onShow={() => this.scrollRefToTop(ref)}>
                            <BorderedSegment borderColor={STRATEGIC_PARTNER.PALETTE.PRIMARY}>
                                <p>
                                    <b {...toggleable}>{ratedClass.groupClass.documentDescription}</b>
                                    <Icon style={{float: 'right'}} name={'chevron up'} {...toggleable}/>
                                </p>
                                <p><b>Eligibility</b></p>

                                <CustomGrid columns={4} className={"small"}>
                                    <CustomGrid.Column>
                                        <div className={"neutral600Text"}><b>Class description</b></div>
                                        {ratedClass.groupClass.documentDescription}
                                    </CustomGrid.Column>
                                    <CustomGrid.Column>
                                        <div className={"neutral600Text"}><b>Actively at Work</b></div>
                                        {ratedClass.contractTerms?.eligibility?.activelyAtWorkHours && `${ratedClass.contractTerms?.eligibility?.activelyAtWorkHours} hours per week`}
                                    </CustomGrid.Column>
                                    <CustomGrid.Column width={6}>
                                        <div className={"neutral600Text"}><b>New hire waiting period</b></div>
                                        {ratedClass.contractTerms?.newHireWaitingPeriod}
                                    </CustomGrid.Column>
                                    {ratedClass.contractTerms?.eligibility?.subjectToERISA !== null &&
                                    <CustomGrid.Column>
                                        <div className={"neutral600Text"}><b>Subject to ERISA</b></div>
                                        {ratedClass.contractTerms?.eligibility?.subjectToERISA ? "Yes":"No"}
                                    </CustomGrid.Column>}
                                    {/* <CustomGrid.Column>
                                        <div className={"neutral600Text"}><b>Subject to Cobra</b></div>
                                        {ratedClass.contractTerms?.eligibility?.cobra ? "Yes":"No"}
                                    </CustomGrid.Column> */}
                                    {ratedClass.contractTerms?.portability && ratedClass.contractTerms?.portability !== "Excluded" &&
                                        <React.Fragment>
                                            <CustomGrid.Column>
                                                <div className={"neutral600Text"}><b>Portability</b></div>
                                                Included
                                            </CustomGrid.Column>
                                            <CustomGrid.Column>
                                                <div className={"neutral600Text"}><b>SP max port age</b></div>
                                                {data.contract?.spouseMaxPortabilityAge}
                                            </CustomGrid.Column>
                                        </React.Fragment>
                                    }
                                    {ratedClass.contractTerms?.benefitWaitingPeriod > 0 && (
                                      <CustomGrid.Column>
                                        <div className={"neutral600Text"}><b>Late entrants</b></div>
                                        {ratedClass.contractTerms?.benefitWaitingPeriod && `${ratedClass.contractTerms?.benefitWaitingPeriod} days benefit waiting period applies`}
                                        {isBenefitWaitingPeriodPayable && `, payable at ${percentagePayableDuringBenefitWaitingPeriod}%`}
                                      </CustomGrid.Column>
                                    )}
                                    <CustomGrid.Column>
                                        <div className={"neutral600Text"}><b>Benefit termination rules</b></div>
                                        {ratedClass.contractTerms?.eligibility?.benefitTerminationRules}
                                    </CustomGrid.Column>
                                    {(data.contract?.maxEligibilityAge && data.contract.maxEligibilityAge !== MAX_EMPLOYEE_ELIGIBILITY_AGE) && (
                                      <CustomGrid.Column>
                                        <div className={"neutral600Text"}><b>Employee max eligibility age</b></div>
                                        {data.contract.maxEligibilityAge}
                                      </CustomGrid.Column>
                                    )}
                                    {ratedClass.contractTerms?.ageReduction &&
                                    <CustomGrid.Column>
                                        <div className={"neutral600Text"}><b>Age reduction</b></div>
                                        {`${ratedClass.contractTerms?.ageReductionPercentage}% at age ${ratedClass.contractTerms?.ageReductionAge}`}
                                    </CustomGrid.Column>}
                                    <CustomGrid.Column width={12}>
                                        <p className={"small"}><b>Dependent eligibility</b></p>
                                        <CustomGrid>
                                            <CustomGrid.Column width={3}>
                                                <CustomGrid.Column width={3}>
                                                    <div className={"neutral600Text"}><b>Spouse eligibility rules</b></div>
                                                    {ratedClass.contractTerms?.restrictFamilyMembers ? (
                                                        "Dependents are not eligible for coverage"
                                                    ) : (
                                                        getSpouseEligibilityMessage()
                                                    )}
                                                </CustomGrid.Column>
                                                <div className={"neutral600Text"}><b>Child dependent eligibility</b>
                                                </div>
                                                {ratedClass.contractTerms?.restrictFamilyMembers ? "Dependents are not eligible for coverage" :
                                                    ratedClass.contractTerms?.eligibility.onlyUnmarried ? "Only unmarried children are eligible" :
                                                        "Married and unmarried children are eligible"}
                                            </CustomGrid.Column>
                                            {ratedClass.contractTerms?.restrictFamilyMembers === false &&
                                            <CustomGrid.Column width={3}>
                                                <div className={"neutral600Text"}><b>Child(ren) max age</b></div>
                                                {data.contract?.childMaxEligibilityAge}
                                            </CustomGrid.Column>}
                                        </CustomGrid>
                                    </CustomGrid.Column>
                                </CustomGrid>

                                <CustomDivider hidden/>

                                {ratedClassUtil.hasEmployerContribution() &&
                                <React.Fragment>
                                    <p><b>Employer contribution</b></p>
                                    {ratedClassUtil.contributionType() === contributionTypes.flatContribution &&
                                    <CustomGrid columns={4} className={"small"}>
                                        <CustomGrid.Column>
                                            <p>
                                                <span className={"neutral700Text"}><b>Employee</b><br/></span>
                                                {formatMontlyCost(ratedClassUtil.flatContribution(), "/month")}
                                            </p>
                                        </CustomGrid.Column>
                                        <CustomGrid.Column>
                                            <p>
                                                <span className={"neutral700Text"}><b>Employee + spouse</b><br/></span>
                                                {formatMontlyCost(ratedClassUtil.flatContribution(), "/month")}
                                            </p>
                                        </CustomGrid.Column>
                                        <CustomGrid.Column>
                                            <p>
                                                <span
                                                    className={"neutral700Text"}><b>Employee + child(ren)</b><br/></span>
                                                {formatMontlyCost(ratedClassUtil.flatContribution(), "/month")}
                                            </p>
                                        </CustomGrid.Column>
                                        <CustomGrid.Column>
                                            <p>
                                                <span className={"neutral700Text"}><b>Family</b><br/></span>
                                                {formatMontlyCost(ratedClassUtil.flatContribution(), "/month")}
                                            </p>
                                        </CustomGrid.Column>
                                    </CustomGrid>}
                                    {ratedClassUtil.contributionType() === contributionTypes.familyTierContribution &&
                                    <CustomGrid columns={4} className={"small"}>
                                        <CustomGrid.Column>
                                            <p>
                                                <span className={"neutral700Text"}><b>Employee</b><br/></span>
                                                {formatMontlyCost(ratedClassUtil.employeeOnlyContribution(), "/month")}
                                            </p>
                                        </CustomGrid.Column>
                                        <CustomGrid.Column>
                                            <p>
                                                <span className={"neutral700Text"}><b>Employee + spouse</b><br/></span>
                                                {formatMontlyCost(ratedClassUtil.employeeSpouseContribution(), "/month")}
                                            </p>
                                        </CustomGrid.Column>
                                        <CustomGrid.Column>
                                            <p>
                                                <span
                                                    className={"neutral700Text"}><b>Employee + child(ren)</b><br/></span>
                                                {formatMontlyCost(ratedClassUtil.employeeChildrenContribution(), "/month")}
                                            </p>
                                        </CustomGrid.Column>
                                        <CustomGrid.Column>
                                            <p>
                                                <span className={"neutral700Text"}><b>Family</b><br/></span>
                                                {formatMontlyCost(ratedClassUtil.familyContribution(), "/month")}
                                            </p>
                                        </CustomGrid.Column>
                                    </CustomGrid>}
                                    {ratedClassUtil.contributionType() === contributionTypes.ageBandContribution &&
                                    <CustomGrid columns={6}  className={"small"}>
                                        <CustomGrid.Row>
                                            {ageRanges.map(band => {
                                                return <CustomGrid.Column key={band.key} style={{paddingBottom: "10px"}}>
                                                    <p>
                                                        <span className={"neutral700Text"}><b>Age {band.title}</b><br/></span>
                                                        {formatMontlyCost(ratedClassUtil.findAgeBandContribution(band.key), "/month")}
                                                    </p>
                                                </CustomGrid.Column>
                                            })}
                                        </CustomGrid.Row>
                                    </CustomGrid>}
                                    {ratedClassUtil.contributionType() === contributionTypes.familyTierAgeBandContribtion &&
                                    <FamiliyTierAgeBandContributionTable ratedClassUtil={ratedClassUtil}
                                                                         ageRanges={ageRanges}/>
                                    }
                                    {ratedClassUtil.contributionType() === contributionTypes.percentContribution &&
                                    <p className={"neutral600Text"}>{ratedClass.employerContributions[0].percentage}%
                                        employer-paid</p>}
                                    <CustomDivider hidden/>
                                </React.Fragment>}

                                {(ratedClass.contractTerms?.chronicBrokerRequested || ratedClass.contractTerms?.mentalBrokerRequested) &&
                                <React.Fragment>
                                    <p><b>Optional riders</b></p>
                                    {ratedClass.contractTerms?.chronicBrokerRequested &&
                                    <CustomGrid className={"small"}>
                                        <CustomGrid.Column width={3}>
                                            <div className={"neutral600Text"}><b>Rider name</b></div>
                                            Chronic
                                        </CustomGrid.Column>
                                        <CustomGrid.Column width={3}>
                                            <div className={"neutral600Text"}><b>Rider amount</b></div>
                                            {formatMoney(ratedClass.contractTerms?.chronicCoverageLimit)}
                                        </CustomGrid.Column>
                                    </CustomGrid>
                                    }
                                    {ratedClass.contractTerms?.mentalBrokerRequested &&
                                    <CustomGrid className={"small"}>
                                        <CustomGrid.Column width={3}>
                                            <div className={"neutral600Text"}><b>Rider name</b></div>
                                            Mental
                                        </CustomGrid.Column>
                                        <CustomGrid.Column width={3}>
                                            <div className={"neutral600Text"}><b>Rider amount</b></div>
                                            {formatMoney(ratedClass.contractTerms?.mentalCoverageLimit)}
                                        </CustomGrid.Column>
                                    </CustomGrid>
                                    }
                                    <CustomDivider hidden/>
                                </React.Fragment>
                                }

                                <BenefitLimits
                                    contractTerms={ratedClass.contractTerms}
                                    statePostalCode={data?.contract?.issueState}
                                />

                                <CustomDivider hidden/>

                                <p><b>Rate Guarantee Period</b></p>
                                <p>Your initial premium rates are guaranteed for {ratedClass.contractTerms?.rateGuaranteePeriod} months.</p>

                                {ratedClass.contractTerms?.employeeShoppingLink && ratedClass.contractTerms?.employeeShoppingCode &&
                                  <React.Fragment>
                                      <CustomDivider hidden/>
                                      <p><b>Employee education link </b>
                                          <span>
                                              <Popup trigger={<Icon name="info circle" link/>}
                                                     content={`This link can be provided to employees to give them access to the ${STRATEGIC_PARTNER.LABEL} education and recommendation tool.`}
                                                     basic position='top center'/>
                                          </span>
                                      </p>
                                      <p>
                                          Your {<StrategicPartnerLabel/>} education experience link can be <a
                                          href={ratedClass.contractTerms?.employeeShoppingLink} target="_blank"
                                          rel="noopener noreferrer">found
                                          here.</a><br/>
                                          <span
                                              className={"small"}>Passcode: {ratedClass.contractTerms?.employeeShoppingCode}</span>
                                      </p>
                                  </React.Fragment>
                                }
                            </BorderedSegment>
                        </Transition>
                    </Ref>
                )}
            </React.Fragment>
        )
    }

    renderDetails = () => {
        const {data} = this.props

        return (
            <React.Fragment>
                <CustomDivider/>
                <CustomDivider hidden/>

                <Header as={"h4"}>Additional details</Header>
                <CustomDivider hidden/>

                {data?.ratedClasses?.map((ratedClass, index) => {
                    return this.renderRatedClass(ratedClass, index)
                })}

                <CustomDivider hidden/>
                <p><b>Grace Period</b></p>
                <p>
                  A grace period of 31 days will be granted for the payment of each premium falling due after
                  the first premium. During the grace period, this Policy shall continue in force unless the
                  Policyholder gives Us written notice of termination in advance of the date of termination
                  and in accordance with the terms of this Policy.
                </p>
                <p>Please review your specific policy documents for additional detail.</p>
                <CustomDivider hidden/>
                <p><b>Policy Termination</b></p>
                <p>We may terminate the Policy on any Premium Due Date for any of the following reasons:</p>
                <List ordered className="indentation">
                  <List.Item> There is less than 15% participation of those eligible persons who pay all or part of their premium for the Policy. </List.Item>
                  <List.Item> There is less than 100% participation of those eligible persons for a Policyholder-paid plan.</List.Item>
                  <List.Item> The Policyholder does not promptly provide Us with information that is reasonably required, or fails to perform any obligation required by the Policy and applicable law.</List.Item>
                  <List.Item> Fewer than 25 persons are insured under the Policy.</List.Item>
                  <List.Item> We determine that there is a significant change in the size, occupation or age of the eligible class(es) as a result of a corporate transaction such as a merger, divestiture, acquisition, sale or reorganization of the Policyholder and/or its persons.</List.Item>
                  <List.Item> We stop providing the type of coverage under this Policy to all groups in the Issue State. </List.Item>
                </List>
                <p>
                  We reserve the right to review and terminate all class(es) covered under the Policy if any class(es) cease(s) to be covered. <br/>
                  Please review your specific policy documents for additional detail.
                </p>
                <CustomDivider hidden/>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(Overview));
